<template>
	<div class="whole">
		<!-- 机器人批量设置 -->
		<!-- <common-head url="/quantification" :title="returnRobotTit(type)" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="returnRobotTit(type)"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="layoutBox bgGray robotSetup">
				<div class="robotBox">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_dealfor.png" alt=""></div>
							{{$t('tba.currency')}}
						</div>
						<div class="right2">
							{{ botstr }}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_exchange.png" alt=""></div>
							{{$t('tba.exchange')}}
						</div>
						<div class="right2">
							{{ exchange }}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_strategy.png" alt=""></div>
							{{$t('tba.strategy')}}
						</div>
						<div class="right2">
							{{ returnRobot(type) }}
						</div>
					</div>
					<div class="list-cell" v-if="type == 'wangge' || type == 'wangge2' || type == 'wangge_m' || type == 'wangge_f' || type == 'wangge_x' || type == 'marting_d'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_loop.png" alt=""></div>
							{{$t('tba.Cycleway')}}
						</div>
						<div class="right2">
							<van-radio-group v-model="times" direction="horizontal">
								<!-- 循环方式(1：单次循环；10000：连续循环) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('tba.Onceloop') }}
								</van-radio>
								<van-radio name="10000">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('tba.Continuousloop') }}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div class="list-cell" v-if="type == 'wave_f'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/fangxiang.png" alt=""></div>
							{{$t('tba.zuodanfangxiang')}}
						</div>
						<div class="right2">
							<van-radio-group v-model="robotParam.v8" direction="horizontal">
								<!-- 循环方式(1：做多；2：做空) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('tba.zuoduo') }}
								</van-radio>
								<van-radio name="2">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('tba.zuokong') }}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div class="list-cell" v-if="type == 'wave_f_x_double'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/fangxiang.png" alt=""></div>
							{{$t('tba.zuodanfangxiang')}}
						</div>
						<div class="right2">
							<van-radio-group v-model="robotParam.v4" direction="horizontal">
								<!-- 循环方式(1：做多；2：做空) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('tba.zuoduo') }}
								</van-radio>
								<van-radio name="2">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('tba.zuokong') }}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div class="list-cell" v-if="type == 'wangge_f'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/fangxiang.png" alt=""></div>
							{{$t('tba.zuodanfangxiang')}}
						</div>
						<div class="right2">
							<van-radio-group v-model="robotParam.v10" direction="horizontal">
								<!-- 循环方式(1：做多；2：做空) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('tba.zuoduo') }}
								</van-radio>
								<van-radio name="2">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('tba.zuokong') }}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div class="list-cell" v-if="type == 'wave_f_coin'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/fangxiang.png" alt=""></div>
							{{$t('tba.zuodanfangxiang')}}
						</div>
						<div class="right2">
							<van-radio-group v-model="robotParam.v8" direction="horizontal">
								<!-- 循环方式(1：做多；2：做空) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('tba.zuoduo') }}
								</van-radio>
								<van-radio name="2">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('tba.zuokong') }}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
				</div>
				
				<!-- 海浪趋势设置 -->
				<div class="robotBox" v-if="type == 'wangge_m'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/cishu.png" alt=""></div>
							{{ $t('tba.Replenishmenttimes')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('tba.Profitstopcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Marginofreplenishment')}}
						</div>
						<div class="right1" @click="showPopup">
							<!-- <input class="inp" type="text" v-model="robotParam.v8" /> -->
							<div>{{ $t('tba.setup')}}</div>
							<!-- <div class="icon1"><img src="@/assets/imgs/new_color/set.png" alt=""></div> -->
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Makeupcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v12" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_flat.png" alt=""></div>
							{{ $t('tba.Horizontalpushornot')}}
						</div>
						<div class="right">
							<van-checkbox class="checkbox" v-model="robotParam.v13">
							  <template #icon="props">
							    <img
							      class="img-icon"
							      style="width: 0.32rem"
							      :src="
							        require('@/assets/imgs/new_color/' +
							          (props.checked ? 'icon_choose' : 'icon_unchoose') +
							          '.png')
							      "
							    />
							  </template>
							</van-checkbox>
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.pingtuifudu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v14" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.bucangbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v15" /> 
						</div>
					</div>
					<van-popup v-model="show1" round position="bottom" :style="{ height: '70%',backgroundColor: '#F8F8F8' }">
						<div class="tan">
							<div class="list-cell" style="padding-bottom: 0.35rem;">
								<div class="left">
									<div class="time">{{ $t('tba.Marginofreplenishment')}}</div>
								</div>
								<div class="right" @click="close">{{ $t('tankuang.no')}}</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('tba.shoucibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v5" /> %
								</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('tba.di2cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v6" /> %
								</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('tba.di3cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v7" /> %
								</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('tba.di4cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v8" /> %
								</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('tba.di5cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v9" /> %
								</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('tba.di6cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v10" /> %
								</div>
							</div>
							<div class="list-cell1">
								<div class="left">
									{{ $t('tba.di7cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v11" /> %
								</div>
							</div>
							<div class="quedin" @click="close">确定</div>
						</div>
					</van-popup>
				</div>
				
				<!-- 海浪网格设置 -->
				<div class="robotBox" v-if="type == 'wave'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.OneProfitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('tba.Wanggekuandu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Suofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Bucangjinersuofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> 
						</div>
					</div>
				</div>
				<!-- 智能海浪设置 -->
				<div class="robotBox" v-if="type == 'wave_x'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
				</div>
				<!-- 智能海浪合约设置 -->
				<div class="robotBox" v-if="type == 'wave_f_x_double'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/ganggan_beishu.png" alt=""></div>
							{{ $t('tba.gangganbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3"  @focus="showPeriodChoice1 = true"/> 
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
				</div>
				<!-- 解构马丁设置 -->
				<div class="robotBox" v-if="type == 'wangge_x'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{ botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_repair.png" alt=""></div>
							{{ $t('tba.Replenishmenttimes')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Profitstopcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_range.png" alt=""></div>
							{{ $t('tba.Marginofreplenishment')}}
						</div>
						<div class="right2" @click="showPopup()">
							去设置
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_replenishment.png" alt=""></div>
							{{ $t('tba.Makeupcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v31" /> %
						</div>
					</div>
					<van-popup v-model="show1" round position="bottom" :style="{ height: '50%',backgroundColor: '#F8F8F8'}">
						<div class="list_item0">
							<div class="left">
								<div class="time">{{ $t('tba.Marginofreplenishment')}}</div>
							</div>
							<div class="right" @click="close">{{ $t('tankuang.no')}}</div>
						</div>
						<div class="tan1">
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.shoucibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v5" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v6" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di2cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v7" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v8" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di3cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v9" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v10" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di4cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v11" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v12" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di5cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v13" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v14" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di6cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v15" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v16" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di7cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v17" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v18" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di8cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v19" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v20" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di9cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v21" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v22" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di10cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v23" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v24" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di11cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v25" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v26" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di12cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v27" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v28" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di13cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v29" /> %
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.beishu')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v30" />
									</div>
								</div>
							</div>
							<div class="quedin1" @click="close">确定</div>
						</div>
					</van-popup>
				</div>
				<!-- 定价马丁设置 -->
				<div class="robotBox" v-if="type == 'marting_d'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_repair.png" alt=""></div>
							{{ $t('tba.Replenishmenttimes')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Profitstopcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_range.png" alt=""></div>
							{{ $t('tba.bcsz')}}
						</div>
						<div class="right2" @click="showPopup()">
							去设置
						</div>
					</div>
					<van-popup v-model="show1" round position="bottom" :style="{ height: '50%',backgroundColor: '#F8F8F8'}">
						<div class="list_item0">
							<div class="left">
								<div class="time">{{ $t('tba.bcsz')}}</div>
							</div>
							<div class="right" @click="close">{{ $t('tankuang.no')}}</div>
						</div>
						<div class="tan1">
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.shoucibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v4" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v5" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di2cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v6" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v7" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di3cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v8" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v9" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di4cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v10" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v11" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di5cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v12" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v13" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di6cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v14" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v15" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di7cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v16" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v17" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di8cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v18" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v19" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di9cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v20" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v21" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di10cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v22" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v23" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di11cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v24" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v25" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di12cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v26" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v27" />
									</div>
								</div>
							</div>
							<div class="list_item">
								<div class="item_left">
									<div class="left">
										{{ $t('tba.di13cibucang')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v28" />
									</div>
								</div>
								<div class="item_right">
									<div class="left">
										{{ $t('tba.bcje')}}
									</div>
									<div class="right">
										<input class="inp1" type="text" v-model="robotParam.v29" />
									</div>
								</div>
							</div>
							<div class="quedin1" @click="close">确定</div>
						</div>
					</van-popup>
				</div>
				<!-- 合约-双向对冲设置 -->
				<div class="robotBox" v-if="type == 'double'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.kcl')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{$t('tba.Contract')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.zdcw')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{$t('tba.Contract')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('tba.Marginofreplenishment')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.gangganbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" @focus="showPeriodChoice3 = true" />
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.zhisunjiage')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" />
						</div>
					</div>
				</div>
				<!-- 合约-海浪网格设置 -->
				<div class="robotBox" v-if="type == 'wave_f'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.OneProfitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('tba.Wanggekuandu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Suofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Bucangjinersuofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/ganggan_beishu.png" alt=""></div>
							{{ $t('tba.gangganbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v7"  @focus="showPeriodChoice1 = true"/> 
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
				</div>
				<!-- 增币策略 -->
				<div class="robotBox" v-if="type == 'wave_f_coin'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{ botstr | danwei1}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.OneProfitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('tba.Wanggekuandu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Suofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Bucangjinersuofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/ganggan_beishu.png" alt=""></div>
							{{ $t('tba.gangganbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v7"  @focus="showPeriodChoice1 = true"/> 
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
				</div>
				<!-- 海浪对冲多 -->
				<div class="robotBox" v-if="type == 'wave_f_long'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{ botstr | danwei1}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.OneProfitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Wanggekuandu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Suofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Bucangjinersuofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/ganggan_beishu.png" alt=""></div>
							{{ $t('tba.gangganbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v7"  @focus="showPeriodChoice1 = true"/> 
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
				</div>
				<!-- 海浪对冲空 -->
				<div class="robotBox" v-if="type == 'wave_f_short'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{ botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.OneProfitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('tba.Wanggekuandu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Suofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Bucangjinersuofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/ganggan_beishu.png" alt=""></div>
							{{ $t('tba.gangganbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v7"  @focus="showPeriodChoice1 = true"/> 
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
				</div>
				<!-- 手动策略多 -->
				<div class="robotBox" v-if="type == 'manual_f_long'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/ganggan.png" alt=""></div>
							{{ $t('tba.gangganbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1"  @focus="showPeriodChoice1 = true"/> 
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.zhisunjiage')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" />
						</div>
					</div>
				</div>
				<!-- 手动策略空 -->
				<div class="robotBox" v-if="type == 'manual_f_short'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/ganggan.png" alt=""></div>
							{{ $t('tba.gangganbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1"  @focus="showPeriodChoice1 = true"/> 
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.zhisunjiage')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" />
						</div>
					</div>
				</div>
				<!-- 合约-马丁 -->
				<div class="robotBox" v-if="type == 'wangge_f'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/cishu.png" alt=""></div>
							{{ $t('tba.Replenishmenttimes')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('tba.Profitstopcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Marginofreplenishment')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Makeupcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_flat.png" alt=""></div>
							{{ $t('tba.Horizontalpushornot')}}
						</div>
						<div class="right">
							<van-checkbox class="checkbox" v-model="robotParam.v7">
							  <template #icon="props">
							    <img
							      class="img-icon"
							      style="width: 0.32rem"
							      :src="
							        require('@/assets/imgs/new_color/' +
							          (props.checked ? 'icon_choose' : 'icon_unchoose') +
							          '.png')
							      "
							    />
							  </template>
							</van-checkbox>
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.bucangbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v8" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/ganggan_beishu.png" alt=""></div>
							{{ $t('tba.gangganbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v9"  @focus="showPeriodChoice1 = true"/> 
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
				</div>
				
				<!-- 智能马丁设置 -->
				<div class="robotBox" v-if="type == 'wangge'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/cishu.png" alt=""></div>
							{{ $t('tba.Replenishmenttimes')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('tba.Profitstopcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Marginofreplenishment')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Makeupcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_flat.png" alt=""></div>
							{{ $t('tba.Horizontalpushornot')}}
						</div>
						<div class="right">
							<van-checkbox class="checkbox" v-model="robotParam.v7">
							  <template #icon="props">
							    <img
							      class="img-icon"
							      style="width: 0.32rem"
							      :src="
							        require('@/assets/imgs/new_color/' +
							          (props.checked ? 'icon_choose' : 'icon_unchoose') +
							          '.png')
							      "
							    />
							  </template>
							</van-checkbox>
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.bucangbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v8" /> 
						</div>
					</div>
				</div>
				<!-- 底部追踪设置 -->
				<div class="robotBox" v-if="type == 'wangge2'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_clock.png" alt=""></div>
							{{ $t('tba.cycle')}}
						</div>
						<div class="right">
							<van-popover
							  v-model="showPopover1"
							  trigger="click"
							  :actions="actions1"
							  @select="onSelect1"
							  placement="bottom-start"
							>
							  <template #reference>
							    <span>
									{{robotParam.v2}}
							    </span>
							  </template>
							</van-popover>
							<img class="qpxl" src="../../assets/imgs/icon/xiangxia.png" >
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_jiang.png" alt=""></div>
							{{ $t('tba.extentContinuousDecline')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/cishu.png" alt=""></div>
							{{ $t('tba.Replenishmenttimes')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> {{ $t('tba.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('tba.Profitstopcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('tba.Marginofreplenishment')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v7" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('tba.Makeupcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v8" /> %
						</div>
					</div>
				</div>
				<div class="robotBox" v-if="type == 'manual_f_long' || type == 'manual_f_short'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.zhiyingjiage')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3"/>
						</div>
					</div>
				</div>
				<div class="robotBox" v-else v-if="type != 'double'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('tba.zhisunjiage')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v16"  v-if="type == 'wangge_m'"/>
							<input class="inp" type="text" v-model="robotParam.v9"  v-if="type == 'wangge' || type == 'wangge2'"/>
							<input class="inp" type="text" v-model="robotParam.v11"  v-if="type == 'wangge_f'"/>
							<input class="inp" type="text" v-model="robotParam.v32"  v-if="type == 'wangge_x'"/>
							<input class="inp" type="text" v-model="robotParam.v30"  v-if="type == 'marting_d'"/>
							<van-radio-group v-model="stop_price" direction="horizontal" v-if="type == 'wave_f'|| type == 'wave'|| type == 'wave_x' || type == 'wave_f_x_double' || type == 'wave_f_long' || type == 'wave_f_short'">
								<!-- 循环方式(1：单次循环；10000：连续循环) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  固定止损
								</van-radio>
								<van-radio name="2">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  浮动止损
								</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div v-if="stop_price == '1'">
						<div class="mid_list-cell" v-if="type == 'wave_f'|| type == 'wave'|| type == 'wave_x' || type == 'wave_f_x_double' || type == 'wave_f_long' || type == 'wave_f_short'">
							<div class="left">
								固定止损
							</div>
							<div class="right" v-if="type == 'wave'">
								<input class="inp" type="text" v-model="robotParam.v7" />U
							</div>
							<div class="right" v-if="type == 'wave_x'">
								<input class="inp" type="text" v-model="robotParam.v3" />U
							</div>
							<div class="right" v-if="type == 'wave_f'">
								<input class="inp" type="text" v-model="robotParam.v9" />U
							</div>
							<div class="right" v-if="type == 'wave_f_x_double'">
								<input class="inp" type="text" v-model="robotParam.v5" />U
							</div>
							<div class="right" v-if="type == 'wave_f_long' || type == 'wave_f_short'">
								<input class="inp" type="text" v-model="robotParam.v8" />U
							</div>
						</div>
					</div>
					<div v-if="stop_price == '2'">
						<div class="mid_list-cell" v-if="type == 'wave_f'|| type == 'wave'|| type == 'wave_x' || type == 'wave_f_x_double' || type == 'wave_f_long' || type == 'wave_f_short'">
							<div class="left">
								浮动止损
							</div>
							<div class="right" v-if="type == 'wave'">
								<input class="inp" type="text" v-model="robotParam.v7" />%
							</div>
							<div class="right" v-if="type == 'wave_x'">
								<input class="inp" type="text" v-model="robotParam.v3" />%
							</div>
							<div class="right" v-if="type == 'wave_f'">
								<input class="inp" type="text" v-model="robotParam.v9" />%
							</div>
							<div class="right" v-if="type == 'wave_f_x_double'">
								<input class="inp" type="text" v-model="robotParam.v5" />%
							</div>
							<div class="right" v-if="type == 'wave_f_long' || type == 'wave_f_short'">
								<input class="inp" type="text" v-model="robotParam.v8" />%
							</div>
						</div>
					</div>
				</div>
				<!-- 放暴跌 -->
				<div class="robotBox" v-if="type != 'wave_f_coin' && type != 'double'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/fangbaodie.png" alt=""></div>
							{{ $t('tba.AntiRiotFall')}}
						</div>
						<div class="right">
							<van-switch v-model="robotParam.v10" active-color="#5569FC " inactive-color="#F7F7FA" size="18px"  v-if="type == 'wangge'"/>
							<van-switch v-model="robotParam.v10" active-color="#5569FC " inactive-color="#F7F7FA" size="18px"  v-if="type == 'wangge2'"/>
							<van-switch v-model="robotParam.v8" active-color="#5569FC " inactive-color="#F7F7FA" size="18px"  v-if="type == 'wave'"/>
							<van-switch v-model="robotParam.v4" active-color="#5569FC " inactive-color="#F7F7FA" size="18px"  v-if="type == 'wave_x'"/>
							<van-switch v-model="robotParam.v17" active-color="#5569FC " inactive-color="#F7F7FA" size="18px"  v-if="type == 'wangge_m'"/>
							<van-switch v-model="robotParam.v33" active-color="#5569FC " inactive-color="#F7F7FA" size="18px"  v-if="type == 'wangge_x'"/>
							<van-switch v-model="robotParam.v31" active-color="#5569FC" inactive-color="#F7F7FA" size="18px"  v-if="type == 'marting_d'"/>
							<van-switch v-model="robotParam.v10" active-color="#5569FC " inactive-color="#F7F7FA" size="18px"  v-if="type == 'wave_f'"/>
							<van-switch v-model="robotParam.v6" active-color="#5569FC" inactive-color="#F7F7FA" size="18px"  v-if="type == 'wave_f_x_double'"/>
							<van-switch v-model="robotParam.v12" active-color="#5569FC " inactive-color="#F7F7FA" size="18px"  v-if="type == 'wangge_f'"/>
							<van-switch v-model="robotParam.v9" active-color="#5569FC " inactive-color="#F7F7FA" size="18px"  v-if="type == 'wave_f_long' || type == 'wave_f_short'"/>
							<van-switch v-model="robotParam.v4" active-color="#5569FC " inactive-color="#F7F7FA" size="18px"  v-if="type == 'manual_f_long' || type == 'manual_f_short'"/>
						</div>
					</div>
					<div v-if="type == 'wangge' && robotParam.v10 || type == 'wangge2' && robotParam.v10 || type == 'wave' && robotParam.v8 || type == 'wave_x' && robotParam.v4 || type == 'wangge_m' && robotParam.v17 || type == 'wangge_x' && robotParam.v33 || type == 'marting_d' && robotParam.v31 || type == 'wave_f' && robotParam.v10 || type == 'wave_f_x_double' && robotParam.v6 || type == 'wangge_f' && robotParam.v12 || type == 'wave_f_long' && robotParam.v9 || type == 'wave_f_short' && robotParam.v9 || type == 'manual_f_long' && robotParam.v4 || type == 'manual_f_short' && robotParam.v4">
						<div class="mid_list-cell">
							<div class="left">
								{{ $t('tba.CycleTime')}}
							</div>
							<van-popover
							  v-model="showPopover"
							  trigger="click"
							  :actions="actions"
							  @select="onSelect"
							  placement="bottom-end"
							>
							  <template #reference>
							    <div class="right" v-if="type == 'wangge'">
									{{robotParam.v11}}
							    	<img src="../../assets/imgs/icon/xiangxia.png" >
							    </div>
								<div class="right" v-if="type == 'wangge2'">
									{{robotParam.v11}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="type == 'wave'">
									{{robotParam.v9}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="type == 'wave_x'">
									{{robotParam.v5}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="type == 'wangge_m'">
									{{robotParam.v18}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="type == 'wangge_x'">
									{{robotParam.v34}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="type == 'marting_d'">
									{{robotParam.v32}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="type == 'wave_f'">
									{{robotParam.v11}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="type == 'wave_f_x_double'">
									{{robotParam.v7}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="type == 'wangge_f'">
									{{robotParam.v13}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="type == 'wave_f_long' || type == 'wave_f_short'">
									{{robotParam.v10}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="type == 'manual_f_long' || type == 'manual_f_short'">
									{{robotParam.v5}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
							  </template>
							</van-popover>
						</div>
						<div class="mid_list-cell">
							<div class="left">
								{{ $t('tba.DeclineRange')}}
							</div>
							<div class="right" v-if="type == 'wangge'">
								<input class="inp" type="text" v-model="robotParam.v12" />%
							</div>
							<div class="right" v-if="type == 'wangge2'">
								<input class="inp" type="text" v-model="robotParam.v12" />%
							</div>
							<div class="right" v-if="type == 'wave'">
								<input class="inp" type="text" v-model="robotParam.v10" />%
							</div>
							<div class="right" v-if="type == 'wave_x'">
								<input class="inp" type="text" v-model="robotParam.v6" />%
							</div>
							<div class="right" v-if="type == 'wangge_m'">
								<input class="inp" type="text" v-model="robotParam.v19" />%
							</div>
							<div class="right" v-if="type == 'wangge_x'">
								<input class="inp" type="text" v-model="robotParam.v35" />%
							</div>
							<div class="right" v-if="type == 'marting_d'">
								<input class="inp" type="text" v-model="robotParam.v33" />%
							</div>
							<div class="right" v-if="type == 'wave_f'">
								<input class="inp" type="text" v-model="robotParam.v12" />%
							</div>
							<div class="right" v-if="type == 'wave_f_x_double'">
								<input class="inp" type="text" v-model="robotParam.v8" />%
							</div>
							<div class="right" v-if="type == 'wangge_f'">
								<input class="inp" type="text" v-model="robotParam.v14" />%
							</div>
							<div class="right" v-if="type == 'wave_f_long' || type == 'wave_f_short'">
								<input class="inp" type="text" v-model="robotParam.v11" />%
							</div>
							<div class="right" v-if="type == 'manual_f_long' || type == 'manual_f_short'">
								<input class="inp" type="text" v-model="robotParam.v6" />%
							</div>
						</div>
					</div>
				</div>
				<!-- 解套 -->
				<div class="robotBox" v-if="type != 'wave_f_coin'">
					<div class="list-cell" v-if="type == 'wave_x' || type == 'wave_f_x_double'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/jietao.png" alt=""></div>
							{{ $t('tba.UnravelingMode')}}
						</div>
						<div class="right">
							<!-- <van-switch v-model="robotParam.v13" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wangge'"/>
							<van-switch v-model="robotParam.v13" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wangge2'"/> -->
							<van-switch v-model="robotParam.v11" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wave'"/>
							<van-switch v-model="robotParam.v7" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wave_x'"/>
							<!-- <van-switch v-model="robotParam.v20" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wangge_m'"/>
							<van-switch v-model="robotParam.v36" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wangge_x'"/> -->
							<van-switch v-model="robotParam.v13" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wave_f'"/>
							<van-switch v-model="robotParam.v9" active-color="#5569FC" inactive-color="#F7F7FA" size="18px" v-if="type == 'wave_f_x_double'"/>
							<!-- <van-switch v-model="robotParam.v15" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wangge_f'"/> -->
							<van-switch v-model="robotParam.v12" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wave_f_long' || type == 'wave_f_short'"/>
							<!-- <van-switch v-model="robotParam.v7" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'manual_f_long' || type == 'manual_f_short'"/> -->
						</div>
					</div>
				</div>
				<!-- 止盈条件 -->
				<div class="robotBox" v-if="type === 'wave' || type === 'wave_f' || type === 'wave_x' || type === 'wave_f_x_double' || type === 'wave_f_coin'	">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/zyjg.png" alt=""></div>
							{{ $t('tba.zytj')}}
						</div>
					</div>
					<div class="mid_list-cell">
						<div class="left">
							{{ $t('tba.zhiyingjiage')}}
						</div>
						<div class="right" v-if="type == 'wave'">
							<input class="inp" type="text" v-model="robotParam.v12" />
						</div>
						<div class="right" v-if="type == 'wave_f'">
							<input class="inp" type="text" v-model="robotParam.v14" />
						</div>
						<div class="right" v-if="type == 'wave_x'">
							<input class="inp" type="text" v-model="robotParam.v8" />
						</div>
						<div class="right" v-if="type == 'wave_f_x_double'">
							<input class="inp" type="text" v-model="robotParam.v10" />
						</div>
						<div class="right" v-if="type == 'wave_f_coin'">
							<input class="inp" type="text" v-model="robotParam.v14" />
						</div>
					</div>
					<div class="mid_list-cell">
						<div class="left">
							{{ $t('tba.AccumulatedProfit')}}
						</div>
						<div class="right" v-if="type == 'wave'">
							<input class="inp" type="text" v-model="robotParam.v13" />
						</div>
						<div class="right" v-if="type == 'wave_f'">
							<input class="inp" type="text" v-model="robotParam.v15" />
						</div>
						<div class="right" v-if="type == 'wave_x'">
							<input class="inp" type="text" v-model="robotParam.v9" />
						</div>
						<div class="right" v-if="type == 'wave_f_x_double'">
							<input class="inp" type="text" v-model="robotParam.v11" />
						</div>
						<div class="right" v-if="type == 'wave_f_coin'">
							<input class="inp" type="text" v-model="robotParam.v15" />
						</div>
					</div>
				</div>
				<div class="robotBtns">
					<div class="btn" @click="saveBotParams()">{{ $t('tba.Saveandstart')}}</div>
				</div>
				
				<van-popup class="setResultWin" v-model="show">
					<div>{{ $t('tba.successNumber') }}：{{ succ_num }}</div>
					<div>{{ $t('tba.failNumber') }}：{{ error_num }}</div>
				</van-popup>
			</div>
			<!-- 周期弹出选择 -->
			<van-action-sheet
				  v-model="showPeriodChoice1"
				  :round="false"
				  :actions="periodChoiceSet1"
				  @select="onSelectPeriod1"
				></van-action-sheet>
				<!-- 周期弹出选择3 -->
				<van-action-sheet v-model="showPeriodChoice3" :round="false" :actions="periodChoiceSet3"
					@select="onSelectPeriod3"></van-action-sheet>
			</van-cell-group>
			<van-dialog v-model="isShowPoster" show-cancel-button :message="$t('tba.xiugaifangxiang')" @confirm="yes"></van-dialog>
		</div>
		
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';
export default {
	name: 'robotSetupBatch',
	components: { commonHead },
	data() {
		return {
			last_robotParam: {},
			show: false, //保存成功/失败弹出框
			show1: false,
			// 20210415[start]
			exchange: this.$route.query.exchange, //交易所名称
			type: this.$route.query.type, //策略，wangge:智能马丁/wangge2:底部追踪
			botstr: this.$route.query.botstr, //多个id对应的字符串
			bot_id_set: this.$route.query.bot_id_set, //多个id值
			times: '1', //循环次数-1、单次循环；10000：连续循环
			fangxiang: null,
			isShowPoster: false,
			showPeriodChoice1: false, //弹出周期选择
			showPeriodChoice3: false, //弹出周期选择
			periodChoiceSet1: [{ name: 'x1' }, { name: 'x2' }, { name: 'x5' }, { name: 'x10' }, { name: 'x20' }],
			periodChoiceSet3: [{
				name: 'x1'
			}, {
				name: 'x2'
			}, {
				name: 'x5'
			}, {
				name: 'x10'
			},],
			succ_num: 0, //保存成功个数
			error_num: 0, //保存失败个数
			typeStrategy: '', //策略类型
			robotParam: {}, //批量设置初始参数值
			fangshi: this.$route.query.fangshi,
			showPopover: false,
			showPopover1: false,
			actions: [{ text: '15m' }, { text: '1h' }, { text: '4h' }, { text: '1d' }],
			actions1: [{ text: '15m' }, { text: '1h' }, { text: '4h' }, { text: '1d' }],
			stop_price: "1"
		};
	},
	created() {
		this.getDefaultBotParams();
		let a = this.$route.query.type;
		console.log(a);
	},
	filters: {
		danwei(v) {
			if(v) {
				let arr = v.split(',');
				let arr1 = arr[0].split('/')
				return arr1[1]
			}
		},
		danwei1(v) {
			if(v) {
				let arr = v.split(',');
				let arr1 = arr[0].split('/')
				return arr1[0]
			}
		}
	},
	methods: {
		onSelect(e) {
		  if (this.type == 'wangge') {
			this.robotParam.v11 = e.text
		  } else if  (this.type == 'wangge2'){
			this.robotParam.v11 = e.text
		  }else if (this.type == 'wave'){
			  this.robotParam.v9 = e.text
		  }else if (this.type == 'wave_x'){
			  this.robotParam.v5 = e.text
		  }else if (this.type == 'wangge_m'){
			  this.robotParam.v18 = e.text
		  }else if (this.type == 'wangge_x') {
			  this.robotParam.v34 = e.text
		  }else if (this.type == 'wave_f') {
			  this.robotParam.v11 = e.text
		  }else if (this.type == 'wave_f_x_double') {
			  this.robotParam.v7 = e.text
		  }else if (this.type == 'wangge_f') {
			  this.robotParam.v13 = e.text
		  }else if (this.type == 'wave_f_long' || this.type == 'wave_f_short') {
			  this.robotParam.v10 = e.text
		  }else if (this.type == 'manual_f_long'|| this.type == 'manual_f_short') {
			  this.robotParam.v5 = e.text
		  }
		},
		onSelect1(e) {
			this.robotParam.v2 = e.text
		},
		onClickLeft() {
			this.$router.back(-1);
		},
		yes() {
			this.isShowPoster = false;
			if (this.checkForm()) {
				this.batchSaveSelectedParams()
			} else {
				this.$toast(this.$t('tba.CannotEmpty'))
			}
		},
		showPopup() {
		    this.show1 = true;
		},
		xiala() {
			this.showPeriodChoice1 = true
		},
		close() {
		    this.show1 = false;
		},
		returnRobotTit(type) {
			if (type == 'wangge') {
				// return '智能马丁批量设置'
				return this.$t('tba.wangge') + this.$t('tba.Batchsettings')
			} else if(type == 'wangge2'){
				// return '底部追踪批量设置'
				return this.$t('tba.wangge2') + this.$t('tba.Batchsettings')
			} else if(type == 'wave'){
				// return '海浪网格批量设置'
				return this.$t('tba.wangge3') + this.$t('tba.Batchsettings')
			} else if(type == 'wave_x'){
				// return '海浪网格批量设置'
				return this.$t('tba.wave_x')
			} else if (type == 'wave_x') {
				return this.$t('tba.wave_x')
			} else if (type == 'wave_f_x_double') {
				return this.$t('tba.wave_f_x_double')
			} else if (type == 'wangge_m') {
				return this.$t('tba.wangge_m')
			} else if (type == 'wave_f') {
				return this.$t('tba.wave_f')
			} else if (type == 'wangge_f') {
				return this.$t('tba.wangge_f')
			}  else if (type == 'wave_f_long') {
				return this.$t('tba.wave_f_long')
			} else if (type == 'wave_f_short') {
				return this.$t('tba.wave_f_short')
			} else if (type == 'manual_f_long') {
				return this.$t('tba.manual_f_long')
			} else if (type == 'manual_f_short') {
				return this.$t('tba.manual_f_short')
			}  else if (type == 'wave_f_coin') {
				return this.$t('tba.wave_f_coin')
			}  else if (type == 'wangge_x') {
				return this.$t('tba.wangge_x')
			}  else if (type == 'marting_d') {
				return this.$t('tba.marting_d')
			}  else if (type == 'double') {
				return this.$t('tba.double')
			}
		},
		returnRobot(type) {
			if (type == 'wangge') {
				// return '智能马丁'
				return this.$t('tba.wangge')
			} else if(type == 'wangge2'){
				// return '底部追踪'
				return this.$t('tba.wangge2')
			} else if(type == 'wave'){
				// return '底部追踪'
				return this.$t('tba.wangge3')
			} else if(type == 'wave_x'){
				// return '底部追踪'
				return this.$t('tba.wangge3')
			} else if (type == 'wave_f_x_double') {
				return this.$t('tba.wave_f_x_double')
			} else if (type == 'wangge_m') {
				return this.$t('tba.wangge_m')
			} else if (type == 'wave_f') {
				return this.$t('tba.wave_f')
			} else if (type == 'wangge_f') {
				return this.$t('tba.wangge_f')
			}  else if (type == 'wave_f_long') {
				return this.$t('tba.wave_f_long')
			} else if (type == 'wave_f_short') {
				return this.$t('tba.wave_f_short')
			} else if (type == 'manual_f_long') {
				return this.$t('tba.manual_f_long')
			} else if (type == 'manual_f_short') {
				return this.$t('tba.manual_f_short')
			}  else if (type == 'wave_f_coin') {
				return this.$t('tba.wave_f_coin')
			}  else if (type == 'wangge_x') {
				return this.$t('tba.wangge_x')
			}  else if (type == 'marting_d') {
				return this.$t('tba.marting_d')
			}  else if (type == 'double') {
				return this.$t('tba.double')
			}
		},
		// 判断参数输入框是否为空
		checkForm() {
			this.last_robotParam = JSON.parse(JSON.stringify(this.robotParam))
			console.log(this.last_robotParam);
			if (this.last_robotParam.v1 === '') {
				return false
			}
			if (this.last_robotParam.v2 === '') {
				return false
			}
			if (this.last_robotParam.v3 === '') {
				return false
			}
			if (this.last_robotParam.v4 === '') {
				return false
			}
			if (this.last_robotParam.v5 === '') {
				return false
			}
			if (this.last_robotParam.v6 === '') {
				return false
			}
			
			if(this.type == 'double'){
				this.last_robotParam.v5 = this.last_robotParam.v5.slice(1)
				if (this.last_robotParam.v6 === '') {
					return false
				}
			}
			if (this.type == 'wangge2') {
				if (this.last_robotParam.v7 === '') {
					return false
				}
				if (this.last_robotParam.v8 === '') {
					return false
				}
				if(this.last_robotParam.v10 == true) {
					this.last_robotParam.v10 = 1;
				}else {
					this.last_robotParam.v10 = 0;
				}
				if(this.last_robotParam.v13 == true) {
					this.last_robotParam.v13 = 1;
				}else {
					this.last_robotParam.v13 = 0;
				}
			}
			if (this.type == 'wave') {
				if(this.last_robotParam.v8 == true) {
					this.last_robotParam.v8 = 1;
				}else {
					this.last_robotParam.v8 = 0;
				}
				if(this.last_robotParam.v11 == true) {
					this.last_robotParam.v11 = 1;
				}else {
					this.last_robotParam.v11 = 0;
				}
			}
			if (this.type == 'wave_x') {
				if(this.last_robotParam.v4 == true) {
					this.last_robotParam.v4 = 1;
				}else {
					this.last_robotParam.v4 = 0;
				}
				if(this.last_robotParam.v7 == true) {
					this.last_robotParam.v7 = 1;
				}else {
					this.last_robotParam.v7 = 0;
				}
			}
			if (this.type == 'wangge_x') {
				if(this.last_robotParam.v33 == true) {
					this.last_robotParam.v33 = 1;
				}else {
					this.last_robotParam.v33 = 0;
				}
				if(this.last_robotParam.v36 == true) {
					this.last_robotParam.v36 = 1;
				}else {
					this.last_robotParam.v36 = 0;
				}
			}
			if (this.type == 'marting_d') {
				if(this.last_robotParam.v31 == true) {
					this.last_robotParam.v31 = 1;
				}else {
					this.last_robotParam.v31 = 0;
				}
			}
			if(this.type == 'wangge') {
				if(this.last_robotParam.v7 == true) {
					this.last_robotParam.v7 = 1;
				}else {
					this.last_robotParam.v7 = 0;
				}
				if(this.last_robotParam.v10 == true) {
					this.last_robotParam.v10 = 1;
				}else {
					this.last_robotParam.v10 = 0;
				}
				if(this.last_robotParam.v13 == true) {
					this.last_robotParam.v13 = 1;
				}else {
					this.last_robotParam.v13 = 0;
				}
			}else if(this.type == 'wangge_m') {
				if(this.last_robotParam.v13 == true) {
					this.last_robotParam.v13 = 1;
				}else {
					this.last_robotParam.v13 = 0;
				}
				if(this.last_robotParam.v17 == true) {
					this.last_robotParam.v17 = 1;
				}else {
					this.last_robotParam.v17 = 0;
				}
				if(this.last_robotParam.v20 == true) {
					this.last_robotParam.v20 = 1;
				}else {
					this.last_robotParam.v20 = 0;
				}
			}
			if(this.type == 'wave_f') {
				this.sub_type = 1
				this.last_robotParam.v7 = this.last_robotParam.v7.slice(1)
				if (this.last_robotParam.v9 === '') {
					return false
				}
				if(this.last_robotParam.v10 == true) {
					this.last_robotParam.v10 = 1;
				}else {
					this.last_robotParam.v10 = 0;
				}
				if(this.last_robotParam.v13 == true) {
					this.last_robotParam.v13 = 1;
				}else {
					this.last_robotParam.v13 = 0;
				}
			}
			if(this.type == 'wave_f_x_double') {
				this.sub_type = 1
				this.last_robotParam.v3 = this.last_robotParam.v3.slice(1)
				if (this.last_robotParam.v5 === '') {
					return false
				}
				if(this.last_robotParam.v6 == true) {
					this.last_robotParam.v6 = 1;
				}else {
					this.last_robotParam.v6 = 0;
				}
				if(this.last_robotParam.v9 == true) {
					this.last_robotParam.v9 = 1;
				}else {
					this.last_robotParam.v9 = 0;
				}
			}
			if(this.type == 'wave_f_long') {
				this.sub_type = 1
				this.last_robotParam.v7 = this.last_robotParam.v7.slice(1)
				if (this.last_robotParam.v8 === '') {
					return false
				}
				if(this.last_robotParam.v9 == true) {
					this.last_robotParam.v9 = 1;
				}else {
					this.last_robotParam.v9 = 0;
				}
				if(this.last_robotParam.v12 == true) {
					this.last_robotParam.v12 = 1;
				}else {
					this.last_robotParam.v12 = 0;
				}
			}
			if(this.type == 'wave_f_short') {
				this.sub_type = 1
				this.last_robotParam.v7 = this.last_robotParam.v7.slice(1)
				if (this.last_robotParam.v8 === '') {
					return false
				}
				if(this.last_robotParam.v9 == true) {
					this.last_robotParam.v9 = 1;
				}else {
					this.last_robotParam.v9 = 0;
				}
				if(this.last_robotParam.v12 == true) {
					this.last_robotParam.v12 = 1;
				}else {
					this.last_robotParam.v12 = 0;
				}
			}
			if(this.type == 'wangge_f') {
				if(this.last_robotParam.v7 == true) {
					this.last_robotParam.v7 = 1;
				}else {
					this.last_robotParam.v7 = 0;
				}
				if (this.last_robotParam.v11 === '') {
					return false
				}
				this.last_robotParam.v9 = this.last_robotParam.v9.slice(1);
				if(this.last_robotParam.v12 == true) {
					this.last_robotParam.v12 = 1;
				}else {
					this.last_robotParam.v12 = 0;
				}
				if(this.last_robotParam.v15 == true) {
					this.last_robotParam.v15 = 1;
				}else {
					this.last_robotParam.v15 = 0;
				}
			}
			if(this.type == 'manual_f_long') {
				this.last_robotParam.v1 = this.last_robotParam.v1.slice(1)
				if(this.last_robotParam.v2 == '') {
					this.last_robotParam.v2 = 0
				}
				if(this.last_robotParam.v3 == '') {
					this.last_robotParam.v3 = 0
				}
				if(this.last_robotParam.v4 == true) {
					this.last_robotParam.v4 = 1;
				}else {
					this.last_robotParam.v4 = 0;
				}
				if(this.last_robotParam.v7 == true) {
					this.last_robotParam.v7 = 1;
				}else {
					this.last_robotParam.v7 = 0;
				}
			}
			if(this.type == 'manual_f_short') {
				this.last_robotParam.v1 = this.last_robotParam.v1.slice(1)
				if(this.last_robotParam.v2 == '') {
					this.last_robotParam.v2 = 0
				}
				if(this.last_robotParam.v3 == '') {
					this.last_robotParam.v3 = 0
				}
				if(this.last_robotParam.v4 == true) {
					this.last_robotParam.v4 = 1;
				}else {
					this.last_robotParam.v4 = 0;
				}
				if(this.last_robotParam.v7 == true) {
					this.last_robotParam.v7 = 1;
				}else {
					this.last_robotParam.v7 = 0;
				}
			}
			if(this.type == 'wave_f_coin') {
				this.sub_type = 1
				this.last_robotParam.v7 = this.last_robotParam.v7.slice(1)
				if (this.last_robotParam.v9 === '') {
					return false
				}
			}
			if(this.type == 'wave') {
				if(this.stop_price == "2") {
					this.last_robotParam.v7 = this.last_robotParam.v7 + '%'
				}
			}
			if(this.type == 'wave_x') {
				if(this.stop_price == "2") {
					this.last_robotParam.v3 = this.last_robotParam.v3 + '%'
				}
			}
			if(this.type == 'wave_f') {
				if(this.stop_price == "2") {
					this.last_robotParam.v9 = this.last_robotParam.v9 + '%'
				}
			}
			if(this.type == 'wave_f_x_double') {
				if(this.stop_price == "2") {
					this.last_robotParam.v5 = this.last_robotParam.v5 + '%'
				}
			}
			if(this.type == 'double') {
				if(this.stop_price == "2") {
					this.last_robotParam.v6 = this.last_robotParam.v6 + '%'
				}
			}
			if(this.type == 'wave_f_long' || this.type == 'wave_f_short') {
				if(this.stop_price == "2") {
					this.last_robotParam.v8 = this.last_robotParam.v8 + '%'
				}
			}
			console.log(9999999999,this.stop_price);
			return true
		},
		// 获取初始数据
		getDefaultBotParams() {
			this.$post2('Robot/api/index/getDefaultParams', {
				type: this.type
			})
				.then(res => {
					let a = res.param_arr
					let b = {}
					Object.keys(a).sort().map(item=>b[item] = a[item]);
					this.robotParam = b;
					if(res.type == 'wangge' || res.type == 'wangge_f'){
						if(!this.robotParam.v8) {
							this.robotParam.v8 = 2;
						}
						if(this.robotParam.v7 == 0) {
							this.robotParam.v7 = false;
						}else{
							this.robotParam.v7 = true;
						}
					}
					if (res.type == 'double') {
						this.robotParam.v5 = 'x' + this.robotParam.v5;
						if(!this.robotParam.v6) {
							this.robotParam.v6 = 0;
						}
					}
					if(res.type == 'wave_f') {
						this.fangxiang = this.robotParam.v8;
						this.robotParam.v7 = 'x' + this.robotParam.v7;
						if(!this.robotParam.v9) {
							this.robotParam.v9 = 0;
						}
						if(this.robotParam.v10 == 0) {
							this.robotParam.v10 = false;
						}else{
							this.robotParam.v10 = true;
						}
						if(this.robotParam.v13 == 0) {
							this.robotParam.v13 = false;
						}else{
							this.robotParam.v13 = true;
						}
					}
					if(res.type == 'wave_f_x_double') {
						this.fangxiang = this.robotParam.v4;
						this.robotParam.v3 = 'x' + this.robotParam.v3;
						if(!this.robotParam.v5) {
							this.robotParam.v5 = 0;
						}
						if(this.robotParam.v6 == 0) {
							this.robotParam.v6 = false;
						}else{
							this.robotParam.v6 = true;
						}
						if(this.robotParam.v9 == 0) {
							this.robotParam.v9 = false;
						}else{
							this.robotParam.v9 = true;
						}
					}
					if(res.type == 'wave_f_long') {
						this.fangxiang = this.robotParam.v8;
						this.robotParam.v7 = 'x' + this.robotParam.v7;
						if(!this.robotParam.v8) {
							this.robotParam.v8 = 0;
						}
						if(this.robotParam.v9 == 0) {
							this.robotParam.v9 = false;
						}else{
							this.robotParam.v9 = true;
						}
						if(this.robotParam.v12 == 0) {
							this.robotParam.v12 = false;
						}else{
							this.robotParam.v12 = true;
						}
					}
					if(res.type == 'wave_f_short') {
						this.fangxiang = this.robotParam.v8;
						this.robotParam.v7 = 'x' + this.robotParam.v7;
						if(!this.robotParam.v8) {
							this.robotParam.v8 = 0;
						}
						if(this.robotParam.v9 == 0) {
							this.robotParam.v9 = false;
						}else{
							this.robotParam.v9 = true;
						}
						if(this.robotParam.v12 == 0) {
							this.robotParam.v12 = false;
						}else{
							this.robotParam.v12 = true;
						}
					}
					if(res.type == 'wangge_f') {
						this.fangxiang = this.robotParam.v10;
						this.robotParam.v9 = 'x' + this.robotParam.v9;
						if(!this.robotParam.v11) {
							this.robotParam.v11 = 0;
						}
						if(this.robotParam.v12 == 0) {
							this.robotParam.v12 = false;
						}else{
							this.robotParam.v12 = true;
						}
						if(this.robotParam.v15 == 0) {
							this.robotParam.v15 = false;
						}else{
							this.robotParam.v15 = true;
						}
					}
					if(res.type == 'wangge_m'){
						if(!this.robotParam.v15) {
							this.robotParam.v15 = 2;
						}
						if(this.robotParam.v13 == 0) {
							this.robotParam.v13 = false;
						}else{
							this.robotParam.v13 = true;
						}
					}
					if(res.type == 'wangge'){
						if(!this.robotParam.v9) {
							this.robotParam.v9 = 0;
						}
						if(this.robotParam.v10 == 0) {
							this.robotParam.v10 = false;
						}else{
							this.robotParam.v10 = true;
						}
						if(this.robotParam.v13 == 0) {
							this.robotParam.v13 = false;
						}else{
							this.robotParam.v13 = true;
						}
					}
					if(res.type == 'wangge2'){
						if(!this.robotParam.v9) {
							this.robotParam.v9 = 0;
						}
						if(this.robotParam.v10 == 0) {
							this.robotParam.v10 = false;
						}else{
							this.robotParam.v10 = true;
						}
						if(this.robotParam.v13 == 0) {
							this.robotParam.v13 = false;
						}else{
							this.robotParam.v13 = true;
						}
					}
					if(res.type == 'wave'){
						if(!this.robotParam.v7) {
							this.robotParam.v7 = 0;
						}
						if(this.robotParam.v8 == 0) {
							this.robotParam.v8 = false;
						}else{
							this.robotParam.v8 = true;
						}
						if(this.robotParam.v11 == 0) {
							this.robotParam.v11 = false;
						}else{
							this.robotParam.v11 = true;
						}
					}
					if(res.type == 'wave_x'){
						if(!this.robotParam.v3) {
							this.robotParam.v3 = 0;
						}
						if(this.robotParam.v4 == 0) {
							this.robotParam.v4 = false;
						}else{
							this.robotParam.v4 = true;
						}
						if(this.robotParam.v7 == 0) {
							this.robotParam.v7 = false;
						}else{
							this.robotParam.v7 = true;
						}
					}
					if(res.type == 'wangge_m'){
						if(!this.robotParam.v16) {
							this.robotParam.v16 = 0;
						}
						if(this.robotParam.v17 == 0) {
							this.robotParam.v17 = false;
						}else{
							this.robotParam.v17 = true;
						}
						if(this.robotParam.v20 == 0) {
							this.robotParam.v20 = false;
						}else{
							this.robotParam.v20 = true;
						}
					}
					if(res.type == 'manual_f_long') {
						this.robotParam.v1 = 'x' + this.robotParam.v1;
						if(!this.robotParam.v2) {
							this.robotParam.v2 = 0;
						}
						if(!this.robotParam.v3) {
							this.robotParam.v3 = 0;
						}
						if(this.robotParam.v4 == 0) {
							this.robotParam.v4 = false;
						}else{
							this.robotParam.v4 = true;
						}
						if(this.robotParam.v7 == 0) {
							this.robotParam.v7 = false;
						}else{
							this.robotParam.v7 = true;
						}
					}
					if(res.type == 'manual_f_short') {
						this.robotParam.v1 = 'x' + this.robotParam.v1;
						if(!this.robotParam.v2) {
							this.robotParam.v2 = 0;
						}
						if(!this.robotParam.v3) {
							this.robotParam.v3 = 0;
						}
						if(this.robotParam.v4 == 0) {
							this.robotParam.v4 = false;
						}else{
							this.robotParam.v4 = true;
						}
						if(this.robotParam.v7 == 0) {
							this.robotParam.v7 = false;
						}else{
							this.robotParam.v7 = true;
						}
					}
					if(res.type == 'wave_f_coin') {
						this.fangxiang = this.robotParam.v8;
						this.robotParam.v7 = 'x' + this.robotParam.v7;
						if(!this.robotParam.v9) {
							this.robotParam.v9 = 0;
						}
					}
					if(res.type == 'wangge_x'){
						if(this.robotParam.v33 == 0) {
							this.robotParam.v33 = false;
						}else{
							this.robotParam.v33 = true;
						}
						if(this.robotParam.v36 == 0) {
							this.robotParam.v36 = false;
						}else{
							this.robotParam.v36 = true;
						}
					}
					if(res.type == 'marting_d'){
						if(this.robotParam.v31 == 0) {
							this.robotParam.v31 = false;
						}else{
							this.robotParam.v31 = true;
						}
					}
					if(res.type == 'wave') {
						if(this.robotParam.v7.indexOf("%") == -1) {
							this.stop_price = "1"
						}else {
							this.robotParam.v7 = this.robotParam.v7.split('%')[0]
							this.stop_price = "2"
						}
					}
					if(res.type == 'wave_x') {
						if(this.robotParam.v3.indexOf("%") == -1) {
							this.stop_price = "1"
						}else {
							this.robotParam.v3 = this.robotParam.v3.split('%')[0]
							this.stop_price = "2"
						}
					}
					if(res.type == 'wave_f') {
						if(this.robotParam.v9.indexOf("%") == -1) {
							this.stop_price = "1"
						}else {
							this.robotParam.v9 = this.robotParam.v9.split('%')[0]
							this.stop_price = "2"
						}
					}
					if(res.type == 'wave_f_x_double') {
						if(this.robotParam.v5.indexOf("%") == -1) {
							this.stop_price = "1"
						}else {
							this.robotParam.v5 = this.robotParam.v5.split('%')[0]
							this.stop_price = "2"
						}
					}
					if(res.type == 'double') {
						if(this.robotParam.v6.indexOf("%") == -1) {
							this.stop_price = "1"
						}else {
							this.robotParam.v6 = this.robotParam.v6.split('%')[0]
							this.stop_price = "2"
						}
					}
					if(res.type == 'wave_f_long' || res.type == 'wave_f_short') {
						if(this.robotParam.v8.indexOf("%") == -1) {
							this.stop_price = "1"
						}else {
							this.robotParam.v8 = this.robotParam.v8.split('%')[0]
							this.stop_price = "2"
						}
					}
					// if(res.type == 'wangge') {
					// 	if(this.robotParam.v12) {
					// 		this.robotParam.v12 = this.robotParam.v12*100;
					// 	}
					// }
					// if(res.type == 'wangge2') {
					// 	if(this.robotParam.v12) {
					// 		this.robotParam.v12 = this.robotParam.v12*100;
					// 	}
					// }
					// if(res.type == 'wave') {
					// 	if(this.robotParam.v10) {
					// 		this.robotParam.v10 = this.robotParam.v10*100;
					// 	}
					// }
					// if(res.type == 'wave_x') {
					// 	if(this.robotParam.v6) {
					// 		this.robotParam.v6 = this.robotParam.v6*100;
					// 	}
					// }
					// if(res.type == 'wangge_m') {
					// 	if(this.robotParam.v19) {
					// 		this.robotParam.v19 = this.robotParam.v19*100;
					// 	}
					// }
					// if(res.type == 'wangge_x') {
					// 	if(this.robotParam.v35) {
					// 		this.robotParam.v35 = this.robotParam.v35*100;
					// 	}
					// }
					// if(res.type == 'marting_d') {
					// 	if(this.robotParam.v33) {
					// 		this.robotParam.v33 = this.robotParam.v33*100;
					// 	}
					// }
					// if(res.type == 'wave_f') {
					// 	if(this.robotParam.v12) {
					// 		this.robotParam.v12 = this.robotParam.v12*100;
					// 	}
					// }
					// if(res.type == 'wave_f_x_double') {
					// 	if(this.robotParam.v8) {
					// 		this.robotParam.v8 = this.robotParam.v8*100;
					// 	}
					// }
					// if(res.type == 'wangge_f') {
					// 	if(this.robotParam.v14) {
					// 		this.robotParam.v14 = this.robotParam.v14*100;
					// 	}
					// }
					// if(res.type == 'wave_f_long' || res.type == 'wave_f_short') {
					// 	if(this.robotParam.v11) {
					// 		this.robotParam.v11 = this.robotParam.v11*100;
					// 	}
					// }
					// if(res.type == 'manual_f_long' || res.type == 'manual_f_short') {
					// 	if(this.robotParam.v6) {
					// 		this.robotParam.v6 = this.robotParam.v6*100;
					// 	}
					// }
					console.log(this.times);
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},
		
		// 批量保存并启动
		saveBotParams() {
			if(this.type == 'wave_f') {
				if(this.robotParam.v8 != this.fangxiang) {
					this.isShowPoster = true
				}else {
					if (this.checkForm()) {
						this.batchSaveSelectedParams()
					} else {
						this.$toast(this.$t('tba.CannotEmpty'))
					}
				}
			}else if(this.type == 'wangge_f') {
				if(this.robotParam.v10 != this.fangxiang) {
					this.isShowPoster = true
				}else {
					if (this.checkForm()) {
						this.batchSaveSelectedParams()
					} else {
						this.$toast(this.$t('tba.CannotEmpty'))
					}
				}
			}else if(this.type == 'wave_f_coin') {
				if(this.robotParam.v8 != this.fangxiang) {
					this.isShowPoster = true
				}else {
					if (this.checkForm()) {
						this.batchSaveSelectedParams()
					} else {
						this.$toast(this.$t('tba.CannotEmpty'))
					}
				}
			}else {
				if (this.checkForm()) {
					this.batchSaveSelectedParams()
				} else {
					this.$toast(this.$t('tba.CannotEmpty'))
				}
			}
		},
		
		//方法--------保存并启动
		batchSaveSelectedParams() {
			let toast = this.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				// message: '批量保存参数中...',
				message: this.$t('tba.SaveingParametersbatch'),
			});
			let a = this.robotParam
			let b = {}
			Object.keys(a).sort().map(item=>b[item] = a[item]);
			this.robotParam = b;
			this.$post2('Robot/api/index/batchSaveSelectedParams', {
				bot_id_set: this.bot_id_set,
				type: this.type,
				times: this.times,
				bot_params: this.last_robotParam,
			})
				.then(res => {
				if(this.type == 'wave_f') {
					this.times = 1
					this.robotParam.v7 = 'x' + this.robotParam.v7
					this.fangxiang = this.robotParam.v8;
				}
				if(this.type == 'wave_f_x_double') {
					this.times = 1
					this.robotParam.v3 = 'x' + this.robotParam.v3
					this.fangxiang = this.robotParam.v8;
				}
				if(this.type == 'wangge_f') {
					if(this.robotParam.v7 == 1) {
						this.robotParam.v7 = true;
					}else {
						this.robotParam.v7 = false;
					}
					this.robotParam.v9 =  'x' + this.robotParam.v9
					this.fangxiang = this.robotParam.v10;
				}
					console.log(res);
					this.succ_num = res.succ;
					this.error_num = res.err;
			
					this.show = true;
					// this.$router.push('/quantification')
					toast.clear();
				})
				.catch(e => {
					if(this.type == 'wave_f') {
						this.times = 1
						this.robotParam.v7 = 'x' + this.robotParam.v7
						this.fangxiang = this.robotParam.v8;
					}
					if(this.type == 'wave_f_x_double') {
						this.times = 1
						this.robotParam.v3 = 'x' + this.robotParam.v3
						this.fangxiang = this.robotParam.v4;
					}
					if(this.type == 'wangge_f') {
						if(this.robotParam.v7 == 1) {
							this.robotParam.v7 = true;
						}else {
							this.robotParam.v7 = false;
						}
						this.robotParam.v9 =  'x' + this.robotParam.v9
						this.fangxiang = this.robotParam.v10;
					}
					// console.log(e, "出错");
					// this.$toast.success('保存参数失败！');
					this.$toast.fail(this.$t('tba.' + e));
					toast.clear();
				});
		},
		// 选择周期数据后
		onSelectPeriod1(item) {
			this.showPeriodChoice1 = false;
			this.periodChoice = item.name;
			if(this.type == 'wave_f') {
				this.robotParam.v7 = item.name;
			}else if (this.type == 'wave_f_x_double') {
				this.robotParam.v3 = item.name;
			}else if (this.type == 'wangge_f') {
				this.robotParam.v9 = item.name;
			}else if (this.type == 'wave_f_long') {
				this.robotParam.v7 = item.name;
			}else if (this.type == 'wave_f_short') {
				this.robotParam.v7 = item.name;
			}else if(this.type == 'manual_f_long') {
				this.robotParam.v1 = item.name;
			}else if(this.type == 'manual_f_short') {
				this.robotParam.v1 = item.name;
			}else if (this.type == 'wave_f_coin') {
				this.robotParam.v7 = item.name;
			}
		},
		// 选择周期数据后3
		onSelectPeriod3(item) {
			this.showPeriodChoice3 = false;
			this.periodChoice = item.name;
			if (this.type == 'double') {
				this.robotParam.v5 = item.name;
			}
		},
	}
};
</script>

<style lang="less" scoped>
.setResultWin {
  width: 50%;
  padding: 0.2rem;
  border: 1px solid #eee;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  border-radius: 3px;

  div {
    padding: 0.1rem;
  }
}
</style>

<style lang="less" scoped>
.van-field__control {
	display: block;
	box-sizing: border-box;
	width: 100%;
	min-width: 0;
	margin: 0;
	padding: 0;
	color: #373737;
	// text-align: left;
	text-align: right;
	background-color: transparent;
	border: 0;
	resize: none;
}
.middedStyle {
	color: #a5a5a5;
	background: #f1f1f1;
	font-size: 0.2rem;
	height: 0.8rem;
	padding: 0.15rem;
}
.btnFdivStyle {
	text-align: center;
}
.btnStyle {
	margin-top: 1rem;
	width: 4.2rem;
}
.c_field {
	font-size: 14px;
	padding: 0.2rem 16px;
}
.inputStyle {
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	width: 100%;
	border-bottom: 1px solid #f2f2f2;
	margin-top: 0.1rem;
	margin-bottom: 0.1rem;
	color: #8b7575;
	padding: 0.1rem;
}

.btnOp {
	margin-top: 0rem;
	margin-bottom: 0.1rem;
	text-align: center;
}
.fontStyle {
	font-size: 0.1rem;
	color: #9b9b9b;
	margin: 0.3rem;
}

.iconStyle {
	margin-right: 0.15rem;
}

.ulList > li {
	padding: 0.3rem;
	margin: 0.3rem;
	background: #ffffff;
	border-radius: 5px;
}

.listStyle {
	background: #ffffff;
	border-radius: 5px;
}

.roleBtnStyle {
	margin-left: 0.4rem;
	margin-right: 0.4rem;
	border-radius: 5px;
}

.tpuschool {
	background: #f1f1f3;
	height: 100%;
	width: 100%;
	margin-bottom: 2rem;
}

.whole {
	// background: #ffffff;
}
.title {
	background: #f6f8f9;
	height: 2rem;
	padding: 0.3rem;
}
.bottomBtnStyle {
	width: 100%;
	background: #e1c364;
	border-radius: 5px;
	border: 0px;
}
.titleGuanLiStyle {
	font-size: 0.4rem;
	padding-top: 1.5rem;
}
.titlePStyle {
	margin-bottom: 0.2rem;
	margin-top: 0.3rem;
	padding-top: 0.1rem;
	transform: rotate(180deg);
}

// ----------20200410[start]---------
.layoutBox {
	padding: 0.2rem;
	font-family: PingFangSC-Regular;
}
.robotBox {
	background: white;
	border-radius: .12rem;
	margin-bottom: .2rem;
	overflow: hidden;
	.van-radio--horizontal {
		margin: 0 0 0 0.4rem;
	}
}
.list-cell {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .2rem;
	// border-bottom: 1px solid #EDEDED;
	.left {
		color: #6F7A8B;
		display: flex;
		align-items: center;
	}
	.icon {
		margin-right: .2rem;
		img {
			width: 0.44rem;
			display: block;
		}
	}
	.icon1 {
		margin-left: .1rem;
		img {
			width: .355rem;
			display: block;
		}
	}
	.right {
		display: flex;
		align-items: center;
		color: #5569FC;
		.qpxl {
			margin-left: 0.14rem;
			width: 0.18rem;
			height: 0.1rem;
		}
	}
	.right1 {
		display: flex;
		align-items: center;
		color: #5569FC;
	}
	.right2 {
		color: #333333;
	}
	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}
	.inp1 {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
		background-color: #F8F8F8;
	}
	
}

.list-cell1 {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .25rem;
	.left {
		color: #666666;
		display: flex;
		align-items: center;
	}
	.icon {
		margin-right: .1rem;
		img {
			width: .48rem;
			display: block;
		}
	}
	.right {
		color: #5569FC;
	}
	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}
	.inp1 {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
		background-color: #F8F8F8;
	}
	
}

.robotBtns {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
	.btn {
		font-size: .32rem;
		color: #FFFFFF;
		line-height: .8rem;
		width: 7.02rem;
		height: 0.8rem;
		text-align: center;
		background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
		border-radius: .08rem;
	}
	
}
.tan {
	padding: 0.2rem 0;
	.quedin {
		padding: 0.3rem 1rem;
		background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
		text-align: center;
		color: #FFFFFF;
		border-radius: 6px;
		margin-top: 0.3rem;
	}
}
.list_item0 {
		position: fixed;
		z-index: 999;
		font-size: .28rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.4rem 0;
		width: 90%;
		background-color: rgb(248, 248, 248);
		border-bottom: 1px solid #EDEDED;
		.left {
			.time {
				color: #666666;
				font-size: 0.32rem;
				font-weight: bold;
				align-items: center;
			}
		}
		.icon {
			margin-right: .1rem;
			img {
				width: .48rem;
				display: block;
			}
		}
		.right {
			color: #666666;
			font-size: 0.32rem;
			font-weight: bold;
			align-items: center;
		}
		
	}
.tan1 {
	padding: 0.2rem 0;
	padding-top: 1.26rem;
	padding-bottom: 1.2rem;
	.list_item {
		font-size: .28rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.4rem .25rem;
		border-bottom: 1px solid #EDEDED;
		.item_left {
			display: flex;
			align-items: center;
			.left {
				color: #666666;
				display: flex;
				align-items: center;
			}
			.icon {
				margin-right: .1rem;
				img {
					width: .48rem;
					display: block;
				}
			}
			.right {
				color: #5569FC;
			}
			.inp1 {
				font-weight: bold;
				height: .48rem;
				border: 0;
				width: 1rem;
				text-align: right;
				background-color: #F8F8F8;
			}
		}
		.item_right {
			display: flex;
			align-items: center;
			.left {
				color: #666666;
				display: flex;
				align-items: center;
			}
			.icon {
				margin-right: .1rem;
				img {
					width: .48rem;
					display: block;
				}
			}
			.right {
				color: #5569FC;
			}
			.inp1 {
				font-weight: bold;
				height: .48rem;
				border: 0;
				width: 0.7rem;
				text-align: right;
				background-color: #F8F8F8;
			}
		}
	}
	.quedin1 {
		position: fixed;
		bottom: 0.1rem;
		z-index: 999;
		padding: 0.3rem 3.12rem;
		background: linear-gradient(
		131deg, #527EFF 0%, #5569FC 100%);
		text-align: center;
		color: #FFFFFF;
		border-radius: 6px;
	}
}
.xiala {
	margin-left: 0.04rem;
	img {
		width: 0.22rem;
		height: 0.14rem;
	}
}
.icon_arrR {
	width: 0.2rem;
	height: 0.2rem;
}
.mid_list-cell {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .25rem;
	.left {
		margin-left: 0.56rem;
		color: #666666;
		display: flex;
		align-items: center;
	}
	.icon {
		margin-right: .1rem;
		img {
			width: .48rem;
			display: block;
		}
	}
	.right {
		color: #5569FC;
		display: flex;
		align-items: center;
		img {
			margin-left: 0.14rem;
			width: 0.18rem;
			height: 0.1rem;
		}
	}
	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}
	.inp1 {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
		background-color: #F8F8F8;
	}
	
}
// ----------20200410[end]---------
</style>

<template>
	<div class="journalBox">
		<!-- <common-head :title="$t('tba.Log')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('tba.Log')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<ul class="commonRouter" :class="tabActive == 0 ? 'active-l' : 'active-r'">
				<li :class="tabActive == 0 ? 'item active' : 'item'" @click="routerTo(0)">{{ $t('tba.trade') }}</li>
				<li :class="tabActive == 1 ? 'item active' : 'item'" @click="routerTo(1)">{{ $t('tba.Log') }}</li>
			</ul>
			<div class="commonTabtit">
				<ul class="type">
					<li :class="typeActive == -1 ? 'active' : ''" @click="handleTabType(-1)">{{ $t('pledge.all') }}</li>
					<li :class="typeActive == 1 ? 'active' : ''" @click="handleTabType(1)">{{ $t('tba.Buyin') }}</li>
					<li :class="typeActive == 0 ? 'active' : ''" @click="handleTabType(0)">{{ $t('tba.Sell') }}</li>
				</ul>
				<div class="img" @click="tan()">
					<img  src="@/assets/imgs/new_color/shaixuan.png" alt="" />
				</div>
				<div class="tan" v-if="tan_show" ref="tan_top">
					<div class="content">
						<div class="head_1">{{$t('tba.exchange')}}</div>
						<div class="exe_list">
							<div class="item" :class="{'active':isActive=='huobi'}" @click="isActive = 'huobi'">HUOBI</div>
							<div class="item" :class="{'active':isActive=='bian'}" @click="isActive = 'bian'">BIANCE</div>
							<div class="item" :class="{'active':isActive=='okex'}" @click="isActive = 'okex'">OKEx</div>
							<div class="item" :class="{'active':isActive=='uniswap'}" @click="isActive = 'uniswap'">UNISWAP</div>
						</div>
						<div class="head_1">{{$t('tba.jiaoyileixin')}}</div>
						<div class="exe_list1">
							<div class="item" :class="{'active':fangshi=='spot'}" @click="fangshi = 'spot'">{{$t('tba.Spot')}}</div>
							<div class="item" :class="{'active':fangshi=='swap'}" @click="fangshi = 'swap'">{{$t('tba.contract')}}</div>
						</div>
						<div class="head_2">{{$t('tba.DealFor')}}</div>
						<div class="dui_list">
							<div class="input">
								<input type="txt" name="" id="" value="" v-model="num1" :placeholder="$t('tba.Pleaseentercurrency')"/>
							</div>
							<div class="fenge"></div>
							<div class="input">
								<input type="txt" name="" id="" value="" v-model="num2" :placeholder="$t('tba.Pleaseentercurrency')"/>
							</div>
						</div>
						<div class="button">
							<div class="button_1" @click="quxiao()">{{ $t('tba.cancel') }}</div>
							<div class="button_2" @click="quedin()">{{ $t('tba.yes') }}</div>
						</div>
					</div>
				</div>
				<!-- <div class="exchange" v-if="exchange == 'bian'" @click="showPicker = true">BINANCE <i class="icon_arr_up"></i></div>
				<div class="exchange" v-else @click="showPicker = true">{{ exchange }} <i class="icon_arr_up"></i></div> -->
			</div>
			
			<!-- <div class="layoutBox bgWhite"> -->
			<div class="layoutBox">
				<!-- 加载时，默认执行初始化数据 -->
				<van-list @load="onLoadcashFlow" v-model="billLoading" :finished="billFinished" :finished-text="$t('cashFlow.NoMore')" :loading-text="$t('common.loading')">
					<div class="journalList">
						<log-list v-for="(item, index) in billList" :key="index" :list="item" :exchange="isActive"></log-list>
					</div>
				</van-list>
			</div>
			
			<van-overlay :show="show" @click="zhezhao()" z-index="98" class-name="zhezhao"></van-overlay>
			<!-- 弹出交易所选择 -->
			<!-- <van-action-sheet
				  v-model="showPicker"
				  :round="false"
				  :actions="periodChoiceSet"
				  @select="onSelectPeriod"
				></van-action-sheet> -->
		</div>
		
	</div>
</template>
<script>
	import Vue from 'vue';
	import { Overlay } from 'vant';
	
	Vue.use(Overlay);
import Loading from '@/components/Loading';
import commonHead from '@/components/commonHead';
import logList from './components/logList';
export default {
	name: 'logs',
	components: { 
		Loading,
		commonHead,
		logList,
	},
	data() {
		return {
			num1: '',
			num2: '',
			show: false,
			tan_show: false,
			isActive: '',
			billLoading: false,
			billFinished: false,
			billData: [],
			billList: [],
			title: '运行日志(火币)',
			billPage: 1,
			// exchange: 'huobi',
			total_profit: 0,
			today_profit: 0,
			showPicker: false,
			// -----20200412[start]------
			tabActive: 1,
			typeActive: -1,
			fangshi: '',
			// show: false,
			// 20210415
			// periodChoiceSet: [
			// 	{ name: 'HUOBI', value: 'huobi' },
			// 	{ name: 'BINANCE', value: 'bian' },
			// 	{ name: 'OKEx', value: 'okex' },
			// ],
		};
	},
	created() {
		let userinfo = JSON.parse(localStorage.getItem('userInfo'))
		// // this.userInfo = userinfo
		// console.log(userinfo)
		// // 获取默认交易所信息
		this.isActive = userinfo.default_exchange;
		if(this.$route.query.exchange) {
			this.isActive = this.$route.query.exchange
		}
		if(this.$route.query.fangshi) {
			if(this.$route.query.fangshi == 1) {
				this.fangshi = 'spot'
			}else if(this.$route.query.fangshi == 2) {
				this.fangshi = 'swap'
			}else {
				this.fangshi = this.$route.query.fangshi
			}
		}
		if(this.$route.query.currency_pair) {
			this.num1 = this.$route.query.currency_pair.split('/')[0]
			this.num2 = this.$route.query.currency_pair.split('/')[1]
		}
		if(this.$route.query.num1 && this.$route.query.num2) {
			this.num1 = this.$route.query.num1
			this.num2 = this.$route.query.num2
		}
		  // this.getBillList();
		this.billPage = 1;
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		tan() {
			this.show = !this.show;
			setTimeout(() =>{
				this.tan_show = !this.tan_show;
			},100)
		},
		quxiao() {
			let userinfo = JSON.parse(localStorage.getItem('userInfo'));
			this.isActive = userinfo.default_exchange;
			this.fangshi = '';
			this.show = !this.show;
			setTimeout(() =>{
				this.tan_show = !this.tan_show;
			},100)
		},
		quedin() {
			this.show = !this.show;
			this.onSelectPeriod();
			setTimeout(() =>{
				this.tan_show = !this.tan_show;
			},100)
		},
		zhezhao() {
			this.show = false;
			setTimeout(() =>{
				this.tan_show = false;
			},100)
		},
		// -----20200412[start]------
		routerTo(index) {
			if (index !== this.tabActive) {
				if (index == 0) {
					this.$router.push({
						path: '/transactionRecords',
						query: {
							exchange: this.isActive,
							fangshi: this.fangshi,
							num1: this.num1,
							num2: this.num2
						}
					});
				} else {
					this.$router.push('/journal');
				}
			}
		},
		
		// 切换交易记录类型-全部-1/买入1/卖出0
		handleTabType(index) {
			this.typeActive = index
			this.billLoading = true,
			this.billPage = 1;
			this.billFinished = false;
			this.billList = [];
			this.billData = [];
			
			this.onLoadcashFlow();
		},
		
		// -----20200412[end]------
		
		// 选择交易所弹出框,赋值，更新列表初始化为空
		onSelectPeriod() {
			// this.showPeriodChoice = false;
			// this.exchange = item.value.toLowerCase();
			// if (this.exchange == 'binance') {
			// 	this.exchange = 'bian'
			// }
			this.billLoading = true,
			this.billPage = 1;
			this.billFinished = false;
			this.billList = [];
			this.billData = [];
			// this.showPicker = false;
			// 初始化数据
			this.onLoadcashFlow();
		},
		
		// 获取初始数据
		getBillList() {
			let pair = ''
			if(this.num1 != '' && this.num2 != '') {
				pair = this.num1 + '_' + this.num2
			}
			this.$post2('Robot/api/index/getRunningLogs', {
				page: this.billPage++,
				exchange: this.isActive,
				// type: this.type,
				type: this.typeActive,
				currency: pair,
				ssf: this.fangshi,
			}).then(res => {
				// console.log(res);
				this.billData = res.list;
				for (let i = 0; i < this.billData.length; i++) {
					this.billList.push(this.billData[i]);
				}
				// 加载状态结束
				this.billLoading = false;
				// 数据全部加载完成
			
				if (this.billData.length < 1) {
					this.billFinished = true;
				}
			})
			.catch(e => {
				this.billFinished = true;
				this.billLoading = false;
				this.$toast.fail(e);
			});
		},
		
		// 继续加载数据
		onLoadcashFlow() {
			this.getBillList();
			// 异步充值记录
			
		}
	}
};
</script>
<style lang="less" scoped>
.journalBox {
	font-family: PingFangSC-Regular;
	// width: 100%;
	// height: 100%;
	// overflow-y: auto;

	.journalList {
		.listBox {
			padding: 0.55rem 0;
			margin: 0 0.4rem;
			border-bottom: 1px solid #eee;
		}
		.journalName {
			font-size: 0.35rem;
			span {
				margin-left: 0.2rem;
			}
		}
		.journalDesc {
			font-size: 0.3rem;
			color: #9e9d9d;
			margin-top: 0.15rem;
			line-height: 0.5rem;
		}
		.timeBox {
			color: #999;
			font-size: 0.3rem;
			text-align: center;
			line-height: 0.45rem;
		}
	}

	.dingDanStyle {
		margin: 0.3rem;
		color: #999999;
	}

	.chongZhiStyle {
		width: 3.4rem;
	}

	.queDingStyle {
		background: #ecbb34;
		border: 0;
		width: 3.4rem;
	}

	.tanChuCeng {
		padding: 0.3rem;
	}

	/*.bodySpanStyle {*/
	/*    color: #ecbb34;*/
	/*}*/

	/*.bodyLiStyle {*/
	/*    margin: 0.1rem;*/
	/*    color: #86878d;*/
	/*}*/

	/*.bodyDivStyle {*/
	/*    border-radius: 5px;*/
	/*    background: #ffffff;*/
	/*    margin: 0.3rem;*/
	/*    padding: 0.2rem;*/
	/*}*/
}
</style>

<!-- 20200412[start] -->
<style scoped lang="less">
	.commonRouter {
		padding: 0.4rem 0.4rem 0.3rem 0.4rem;
		background-color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		li {
			color: #5569FC;
			font-size: .32rem;
			width: 3.2rem;
			height:0.68rem;
			border: 1px solid #5569FC;
			border-radius: 0.16rem;
			font-weight: bold;
			// border: 2px solid #FFFFFF;
			// border-radius: .36rem;
			display: flex;
			justify-content: center;
			align-items: center;
			&.active {
				color: #FFFFFF;
				background: #5569FC;
				border: none;
			}
		}
	}
	
	.layoutBox {
		padding: 0 0.2rem;
		min-height: calc(100vh - 2.1rem);
		position: relative;
		
	}
	
	.commonTabtit {
		
		position: relative;
		padding: 0 0.4rem;
		color: #6F7A8B;
		background-color: #FFFFFF;
		font-size: .28rem;
		height: 0.66rem;
		display: flex;
		justify-content: space-between;
		// justify-content: flex-end;
		align-items: center;
		margin-bottom: .2rem;
		border-bottom: 0.05rem solid #FFFFFF;
		.type {
			display: flex;
			align-items: center;
			li {
				line-height: 0.66rem;
				margin-right: .5rem;
				&.active {
					color: #333333;
					font-weight: bold;
					border-bottom: 0.05rem solid #5569FC;
				}
			}
		}
		.img {
			img {
				width: 0.38rem;
				height: 0.36rem;
			}
		}
		.exchange {
			height: .8rem;
			display: flex;
			align-items: center;
			text-transform: uppercase;
			.icon_arr_up {
				width: .1rem;
				height: .1rem;
				border-right: 2px solid #666666;
				border-bottom: 2px solid #666666;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				margin-left: .15rem;
				display: inline-block;
			}
		}
		.tan {
			z-index: 99;
			position: absolute;
			right: 0;
			top: 0.59rem;
			background-color: #FFFFFF;
			width: 100%;
			height: 6.4rem;
			.content {
				margin: 0.3rem 0.4rem;
				.head_1 {
					color: #333333;
					font-size: 0.28rem;
					margin-bottom: 0.16rem;
				}
				.exe_list {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 0.32rem;
					.item {
						font-weight: bold;
						padding: 0.06rem 0.18rem;
						background-color: #F9FAFB;
						color: #999999;
						text-align: center;
						border-radius: 0.04rem;
					}
					.active {
						background-color: #E7ECEF;
						color: #333333;
						position: relative;
					}
					// .active:before {
					//         content: '';
					//         position: absolute;
					//         right: 0;
					//         bottom: 0;
					//         border: 0.12rem solid #1677FF;
					//         border-top-color: transparent;
					//         border-left-color: transparent;
					// }
					// .active:after {
					//     content: '';
					// 	width: 1px;
					// 	height: 3px;
					// 	position: absolute;
					// 	right: 1px;
					// 	bottom: 1px;
					// 	border: 2px solid #fff;
					// 	border-top-color: transparent;
					// 	border-left-color: transparent;
					// 	transform: rotate(45deg);
					// }
				}
				.exe_list1 {
					display: flex;
					align-items: center;
					// justify-content: space-between;
					margin-bottom: 0.32rem;
					.item {
						font-weight: bold;
						padding: 0.06rem 0.34rem;
						background-color: #F9FAFB;
						color: #999999;
						text-align: center;
						margin-left: 0.5rem;
						border-radius: 0.04rem;
						&:first-child {
							margin-left: 0;
						}
					}
					.active {
						background-color: #E7ECEF;
						color: #333333;
						position: relative;
					}
					// .active:before {
					//         content: '';
					//         position: absolute;
					//         right: 0;
					//         bottom: 0;
					//         border: 0.12rem solid #1677FF;
					//         border-top-color: transparent;
					//         border-left-color: transparent;
					// }
					// .active:after {
					//     content: '';
					// 	width: 1px;
					// 	height: 3px;
					// 	position: absolute;
					// 	right: 1px;
					// 	bottom: 1px;
					// 	border: 2px solid #fff;
					// 	border-top-color: transparent;
					// 	border-left-color: transparent;
					// 	transform: rotate(45deg);
					// }
				}
				.head_2 {
					color: #373737;
					font-size: 0.32rem;
					margin-bottom: 0.38rem;
				}
				.dui_list {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 0.48rem;
					.input {
						// border-bottom: 0.02rem solid #2568E4;
						input {
							border: none;
							text-align: center;
							font-size: 0.24rem;
							transform: scale(0.91);
							color: #999999;
							padding: 0.2rem 0;
							background-color: #F9FAFB;
							padding: 0.19rem 0.255rem;
							margin-left: -0.12rem;
							border-radius: 0.08rem;
						}
					}
					.fenge {
						width: 0.04rem;
						height: 0.51rem;
						background-color: #5569FC;
					}
				}
				.button {
					display: flex;
					justify-content: space-between;
					.button_1 {
						background-color: #E7ECEF;
						color: #333333;
						font-size: .32rem;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 3.2rem;
						height: 0.96rem;
						border-radius: 0.16rem;
						// background-repeat: no-repeat;
						// background-position: center center;
						// background-size: 100% 100%;
						// background-image: url(../..//assets/imgs/bag-w.png);
					}
					.button_2 {
						background-color: #5569FC;
						color: #FFFFFF;
						font-size: .32rem;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 3.2rem;
						height: 0.96rem;
						border-radius: 0.16rem;
						// background-repeat: no-repeat;
						// background-position: center center;
						// background-size: 100% 100%;
						// background-image: url(../..//assets/imgs/bag-b.png);
					}
				}
			}
		}
	}
	.zhezhao {
		// height: ;
		top: calc(env(safe-area-inset-top) + 22vh);
	}
	
</style>
<!-- 20200412[end] -->

<template>
	<div class="whole">
		<!-- <title-two url="/geren" title="资产" /> -->
		<common-header
			bg_size="100% calc(env(safe-area-inset-top) + 3.95rem)"
			:title="$t('xinzeng.qianbao')"
			:bg_src="require('../../assets/imgs/new_color/qianbao_bg.png')"
			:is-return="true"
			@onclickRight="onclickRight"
			style="padding-top: env(safe-area-inset-top);"
		>
			<span class="text" slot="text">{{ $t('tba.walletRecord') }}</span>
		</common-header>
		<div class="content">
			<div class="accountInfo">
				<div class="head_txt">账户资产</div>
				<div class="qianbao">
					<div class="item" v-if="danwei1">
						<span class="yen">{{ assetsDitale }}</span>
						<span class="title">{{danwei1}}</span>
					</div>
					<div class="item" v-if="danwei2">
						<span class="yen">{{ sfow }}</span>
						<span class="title">{{danwei2}}</span>
					</div>
					<div class="item" v-if="danwei3">
						<!-- <span class="title">{{ $t('tba.AccountassetsFOW') }}</span> -->
						<span class="yen">{{ fow }}</span>
						<span class="title">{{danwei3}}</span>
					</div>
				</div>
			</div>
			<!-- <div class="new_bgMask"><div class="bgMask"></div></div> -->
			<div class="body1Style">
				<div class="spanFlex6" @click="chargeMoney">
					<div class="img"><img src="../../assets/imgs/new_color/chong_bi.png" /></div>
					<div class="txt">{{ $t('xinzeng.chongbi') }}</div>
				</div>
				<div class="spanFlex6" @click="towithdrawMoney">
					<div class="img"><img src="../../assets/imgs/new_color/ti_bi.png" /></div>
					<div class="txt">{{ $t('xinzeng.tibi') }}</div>
				</div>
				<div class="spanFlex6" @click="transfer">
					<div class="img"><img src="../../assets/imgs/new_color/zhaung_bi.png" /></div>
					<div class="txt">{{ $t('xinzeng.zhuanz') }}</div>
				</div>
<!-- 				<div class="spanFlex6" @click="exchange">
					<div class="img"><img src="../../assets/imgs/new_color/huan_bi.png" /></div>
					<div class="txt">{{ $t('xinzeng.huanbi') }}</div>
				</div> -->
			</div>
			<div class="container bgWhite">
				<div class="zhongjiandeDiv" @click="toRecordMore">
					<div class="head_left">
						<div class="kuai"></div>
						{{ $t('tba.historyRecord') }}
					</div>
					<div class="head_right">
						<span class="txt">查看全部</span>
						<i class="icon_arrR"></i>
					</div>
				</div>
				<div class="bottomDIvStyle">
					<van-list v-model="billLoading" :finished="billFinished" :finished-text="$t('cashFlow.NoMore')" :loading-text="$t('common.loading')" @load="onLoadcashFlow">
						<van-row class="vanRowBoderStyle" v-for="(item, index) in billList" :key="index">
							<van-col class="bottomDivRowColStyle" span="17">
								<p class="mainFontLighterColor bottomDivRowColStyleP2">{{ item.update_at }}</p>
								<div class="bottomDivRowColStylePS">
									<div class="leixin">{{ $t('tba.' + item.type) }}</div>
									<div class="laiqu" v-if="item.from_uid != 0">{{ item.from_username }}</div>
								</div>
							</van-col>
							<van-col span="7">
								<p class="vanCol4PStyle">
									<!-- <span :class="item.amount > 0 ? 'mainColor1' : 'mainColor3'" v-if="item.token == 'USDT'">{{ item.amount }} {{item.token}}</span> -->
									<span :class="item.amount > 0 ? 'mainColor1' : 'mainColor3'">{{ item.amount }} {{ item.token }}</span>
								</p>
							</van-col>
						</van-row>
					</van-list>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from '@/components/Loading';
import commonHeader from '@/components/commonHeader';

export default {
	name: 'assetssecondEdition',
	components: {
		Loading,
		// TitleTwo,
		commonHeader
	},
	data() {
		return {
			billLoading: false,
			billFinished: false,
			billData: [],
			billList: [],
			billPage: 1,

			listData: [],
			assetsDitale: 0,
			sfow: 0,
			fow: 0,
			rewardData: 0,
			amount: '',
			frozen: '',
			filterSet: [],
			showPicker: false,
			cashType: '',
			danwei1: null,
			danwei2: null,
			danwei3: null,
			items: [],
			top: 1,
			bottom: 1,
			isset_tradepwd: null
		};
	},

	filters: {
		amountIsMinus(amount) {
			if (amount.substr(0, 1) == '-') {
				return true;
			} else {
				return false;
			}
		}
	},

	watch: {
		// take(news, old) {
		//   this.billPage = 1;
		//   this.billFinished = false;
		//   this.billList = [];
		// },
	},

	created() {
		this.billPage = 1;
		this.issetTradepwd();
	},
	mounted() {
		this.getFilterSet(), this.getAssets();
		// for (var i = 1; i <= 20; i++) {
		//   this.items.push(i + " - keep walking, be 2 with you.");
		// }
		// this.top = 1;
		// this.bottom = 20;
		//this.getRobbt();
		// this.getSupportedTokens()
	},
	methods: {
		onclickRight() {
			this.$router.push('/walletRecord');
		},
		// ---------20200410 start------
		toRecordMore() {
			this.$router.push('walletRecord');
		},
		// ---------20200410 end------
		refresh(done) {
			setTimeout(() => {
				this.billPage = 1;
				this.getCashflow();
				// var start = this.top - 1;

				// for (var i = start; i > start - 10; i--) {
				//   this.items.splice(0, 0, i + " - keep walking, be 2 with you.");
				// }
				// this.top = this.top - 10;
				done();
			}, 1500);
		},
		infinite(done) {
			this.getCashflow();

			setTimeout(() => {
				var start = this.bottom + 1;
				for (var i = start; i < start + 10; i++) {
					this.items.push(i + ' - keep walking, be 2 with you.');
				}
				this.bottom = this.bottom + 10;

				done();
			}, 1500);
		},
		//methods中的方法
		changeConfirm(val, index) {
			this.billPage = 1;
			this.cashType = val; //传值
			console.log(index); //索引
			this.showPicker = false;
			console.log(this.cashType);
			this.getCashflow();
			// 如果最开始的数据源是数组对象，我的做法是先把数据源变成一位数组(用forEach遍历每一项，push到一个新数组中)，用于展示，然后在confirm事件中，拿到索引，根据索引就可以拿到数组中所对应的对象
		},

		getFilterSet() {
			this.$post2('Pay/Api/Index/getFilterSet').then(res => {
				console.log(res);
				this.filterSet = res;
			});
		},
		// 前往互转页面
		transfer() {
			if (this.assetsDitale > 0 || this.sfow > 0 || this.fow > 0) {
				if (this.isset_tradepwd == 0) {
					this.$router.push({
						path: '/setThePassword',
						query: {
							homepath: '/assets'
						}
					});
				} else {
					this.$router.push('/transfer');
				}
			} else {
				this.$toast('余额不足！');
			}
		},
		// 前往兑换页面
		exchange() {
			if (this.assetsDitale > 0 || this.fow > 0) {
				// if (this.isset_tradepwd == 0) {
				// 	this.$router.push({
				// 		path: '/setThePassword',
				// 		query: {
				// 			homepath: '/assets'
				// 		}
				// 	});
				// } else {
					this.$router.push('/exchange');
				// }
			} else {
				this.$toast('余额不足！');
			}
		},
		exchange_tt() {
			this.$router.push('switch');
		},
		// 获取资产信息
		getAssets() {
			this.$post2('User/Api/Index/userAsset').then(res => {
				console.log(res);
				// 总资产是 amout + frozen
				let t = res.USDT.amount + res.USDT.frozen;
				this.assetsDitale = t.toFixed(2);
				let o1 = Object.keys(res)[0];
				this.danwei1 = o1;
				let t2 = res.SFOW.amount + res.SFOW.frozen;
				this.sfow = t2.toFixed(2);
				let o2 = Object.keys(res)[1];
				this.danwei2 = o2;
				let t3 = res.FOW.amount + res.FOW.frozen;
				this.fow = t3.toFixed(2);
				let o3 = Object.keys(res)[2];
				this.danwei3 = o3;
				// console.log(typeof res.USDT.amount)
				// this.amount =  res.amount
				// + res.frozen
			});
		},

		// 获取 量化交易
		getRobbt() {
			this.$post('Robot/Api/Index/getTokenPair').then(res => {
				console.log(res);
			});
		},

		onLoadcashFlow() {
			this.getCashflow();
			// 异步充值记录
		},

		getCashflow() {
			this.$post2('Pay/Api/Index/cashflow', {
				cashType: this.cashType,
				page: this.billPage++
			}).then(res => {
				this.billData = res.list;
				for (let i = 0; i < this.billData.length; i++) {
					this.billList.push(this.billData[i]);
				}
				// console.log(this.shouyiList);
				// 加载状态结束
				this.billLoading = false;
				// 数据全部加载完成

				console.log(this.billData);
				if (this.billData.length < 1) {
					this.billFinished = true;
				}
				// for (let i = 0; i < res.list.length; i++) {
				//   this.billList.push(res.list[i]);
				// }
				// // 加载状态结束
				// this.billLoading = false;
				// // 数据全部加载完成

				// if (res.list.length < 1) {
				//   this.billFinished = true;
				// }
			});
		},
		// 充币
		chargeMoney() {
			console.log(this.isset_tradepwd);
			this.$router.push('/chargeMoney');
		},
		// 提币
		towithdrawMoney() {
			if (this.assetsDitale > 0 || this.sfow > 0 || this.fow > 0) {
				if (this.isset_tradepwd == 0) {
					this.$router.push({
						path: '/setThePassword',
						query: {
							homepath: '/assets'
						}
					});
				} else {
					this.$router.push('/withdrawMoney');
				}
			} else {
				this.$toast(this.$t('fahongbao.InsufficientBalancePlease'));
			}
		},
		imgToIndex() {
			this.$router.push('/geren');
		},
		toTransactionSetup() {
			this.$router.push('transactionSetup');
		},
		issetTradepwd() {
			this.$post2('/user/api/index/issetTradepwd', {
				noToast: true
			})
				.then(res => {
					// console.log('哈哈哈啊哈哈哈哈哈');
					this.isset_tradepwd = res.isset_tradepwd;
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		}
	}
};
</script>

<style scoped lang="less">
.moreImg {
	width: 0.6rem;
	/* height: 0.2rem; */
	// margin-left: 5rem;
}

.sspanStyleCol22 {
	margin-left: 0.2rem;
}

.vanRowBoderStyle {
	border-bottom: 1px solid #ededed;
	margin: 0 0.3rem;
	padding: 0.15rem 0 0.2rem;
}

.bottomDivRowColStyle {
	// padding: 0.1rem;
}

.vanCol4PStyle {
	font-size: 0.28rem;
	text-align: right;
	// color: #CF4E65;
	margin-top: 0.25rem;
	span {
		font-family: Regular;
	}
}

.bottomDivRowColStylePS {
	// padding-bottom: 0.1rem;
	font-size: 0.28rem;
	line-height: 0.4rem;
	margin-top: 0.08rem;
	display: flex;
	color: #333333;
}

.bottomDivRowColStyleP2 {
	font-size: 0.24rem;
	line-height: 0.35rem;
}

.fontColorStyle {
	// color: #999;
	color: #333;
}

.zhongjiandeDiv {
	height: 0.8rem;
	font-size: 0.32rem;
	padding: 0 0.3rem;
	padding-top: 0.2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.head_left {
		font-size: 0.32rem;
		color: #333333;
		display: flex;
		align-items: center;
		font-weight: bold;
		.kuai {
			width: 0.1rem;
			height: 0.26rem;
			background-color: #2E62FF;
			margin-right: 0.1rem;
		}
	}
	.head_right {
		display: flex;
		align-items: center;
		.txt {
			margin-right: 0.1rem;
			color: #999999;
			font-size: 0.24rem;
		}
		.icon_arrR {
			width: 0.24rem !important;
			height: 0.24rem !important;
			border-top: 0.04rem solid #999;
			border-right: 0.04rem solid #999;
		}
	}
}



.body1Style {
	// margin: 0 0.2rem;
	background-color: #ffffff;
	padding: 0.4rem 1rem 0.4rem 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.spanFlex6 {
		display: flex;
		flex-direction: column;
		align-items: center;
		.img {
			width: 0.6rem;
			height: 0.6rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.txt {
			margin-top: 0.03rem;
			text-align: center;
			font-family: PingFang SC;
			font-weight: 500;
			font-size: 0.28rem;
			color: #333333;
		}
		// &.sbtn1 {
		//  background-image: url('../../assets/imgs/new_color/money_bg.png');
		// }
		// &.sbtn2 {
		// 	  background-image: url('../../assets/imgs/wallet/icon_withdrawmoney.png');
		// }
		// &.sbtn3 {
		// 	  background-image: url('../../assets/imgs/wallet/icon_transferaccounts.png');
		// }
	}
}
.new_bgMask {
	background-color: #ffffff;
	.bgMask {
		margin-top: -0.5rem;
		height: 0.69rem;
		width: 100%;
		background: url(../../assets/imgs/new_color/mask.png) no-repeat center top;
		background-size: 100% 0.69rem;
		position: relative;
		z-index: 100;
	}
}
.whole {
	/* background: #ffffff; */
	font-family: PingFangSC-Regular;
}
.content {
	position: relative;
	// top: 0.88rem;
	padding-top: calc(env(safe-area-inset-top) + 1.08rem);
	left: 0;
	width: 100%;
	// z-index: 9999;
	&::before {
		content: '';
		height: calc(env(safe-area-inset-top) + 3.95rem);
		width: 100%;
		background: url(../../assets/imgs/new_color/qianbao_bg.png) no-repeat center top;
		background-size: 100% 100%;
		display: inline-block;
		position: absolute;
		top: 0;
	}
}
.accountInfo {
	color: white;
	font-size: 0.24rem;
	margin: 0 0.4rem;
	height: 2.82rem;
	border-radius: 0.12rem;
	// margin-top: -2.45rem;
	position: relative;
	z-index: 10;
	// background-image: url(../../assets/imgs/new_color/q_bg.png);
	// background-size: 100% 100%;
	// background-repeat: no-repeat;
	z-index: 99;
	.head_txt {
		padding: 0.4rem 0.4rem 0.8rem 0.4rem;
		color: #FFFFFF;
		font-size: 0.4rem;
		font-family: pingfangSC-Medium;
	}
	.qianbao {
		display: flex;
		align-items: center;
		justify-content: space-around;
		.item {
			width: 33.3%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			text-align: center;
			&:nth-child(2) {
				// margin-top: 0;
				border-right: 0.02rem solid rgba(250, 205, 182, 0.33);
			}
			&:nth-child(2) {
				// margin-top: 0;
				border-left: 0.02rem solid rgba(250, 205, 182, 0.33);
			}
			.title {
				color: #FFFFFF;
				font-size: 0.24rem;
				font-family: PingFang SC;
			}
			.yen {
				color: #FFFFFF;
				font-size: 0.28rem;
				font-family: PingFang SC;
			}
		}
	}
}
.leixin {
	width: 2.3rem;
}
.mainColor1 {
	color: #32bf88 !important;
}
.mainColor3 {
	color: #f5465d !important;
}
.container {
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
}
</style>

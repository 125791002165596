<template>
    <div class="billBox">
        <!-- <title-two url="/newHome" title="今日盈利"/> -->
		<!-- <common-head url="/newHome" :title="$t('tba.Bill')" :is-return="true"> -->
			<!-- <img slot="right" src="@/assets/imgs/icon/icon_log.png" alt="" /> -->
		<!-- </common-head> -->
		<van-nav-bar
		    :title="$t('tba.Bill')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="communityInfo">
				<div class="list">
					<div class="item">
						<div class="i_left">
							<div class="yen">{{ today_profit }} U</div>
							<p>{{ $t('tba.Todaysearnings') }}</p>
						</div>
						<div class="i_right">
							<!-- <img src="../../assets/imgs/new_color/tiaozhuan_r.png" > -->
						</div>
					</div>
					<div class="item" @click="linkToBillback()">
						<div class="i_left">
							<div class="yen">{{ total_profit }} </div>
							<p>{{ $t('tba.Totalrevenue') }}</p>
						</div>
						<div class="i_right">
							<img src="../../assets/imgs/new_color/tiaozhuan_r.png" >
						</div>
					</div>
				</div>
			</div>
			
			<div class="layoutBox">
			    <!-- <van-row gutter="10" class="billNumBox">
			        <van-col span="12">
			            <div class="billNum today">
			                <p>{{ $t("tba.todayProfit") }}(USDT)</p>
			                <span>{{ today_profit }}</span>
			            </div>
			        </van-col>
			        <van-col span="12">
			            <div class="billNum cumulative" @click="linkToBillback()">
			                <p>{{ $t("tba.AccumulatedProfit") }}(USDT)</p>
			                <span>{{ total_profit }}</span>
			            </div>
			        </van-col>
			    </van-row> -->
			    <div class="billList">
					<div class="top">
						<span class="kuai"></span>
						<h3 class="caption">{{ $t('geren.gainRecording') }}</h3>
					</div>
			        <van-list v-model="billLoading" :finished="billFinished" :finished-text="$t('cashFlow.NoMore')"
			                  :loading-text="$t('common.loading')" @load="onLoadcashFlow">
			            <div class="billItem" v-for="(item, index) in billList" :key="index">
			                <div class="billTitle">
								<div class="t_left">
									<div class="name">{{item.currency_pair | name}}</div>
									<div class="name1">/{{item.currency_pair | name1}}</div>
								</div>
								<span class="mainFontLighterColor">{{item.update_time}}</span>
								<!-- <i class="mainFontLightColor">{{ $t("tba.DealFor") }}</i> -->
			                </div>
			                <van-row>
			                    <van-col span="6">
									<div class="billCent1">
									    <span>{{ exchangeNameFilter(item.web) }}</span>
									    <p>{{ $t("tba.exchange") }}</p>
									</div>
			                    </van-col>
			                    <van-col span="11">
			                        <div class="billCent">
			                            <span>{{ item.order_id }}</span>
			                            <p>{{ $t("tba.order") }}</p>
			                        </div>
			                    </van-col>
			                    <van-col span="7">
			                        <div class="billCent">
			                            <span class="billGreen">{{ item.profit }} </span>
			                            <p>{{ $t("tba.Profit") }}({{item | danwei}})</p>
			                        </div>
			                    </van-col>
			
			                </van-row>
			            </div>
			        </van-list>
			    </div>
			</div>
		</div>
		
    </div>
</template>
<script>
    // import TitleTwo from "../../components/titleTwo";
    export default {
        data() {
            return {
                billLoading: false,
                billFinished: false,
                billData: [],
                billList: [],
                billPage: 1,
                total_profit: 0,
                today_profit: 0,
            };
        },
        watch: {
            take(news, old) {
                this.billPage = 1;
                this.billFinished = false;
                this.billList = [];
            },
        },
        filters: {
            // exchangeNameFilter(web) {
            //     if (web == "huobi") {
            //         return $t("tba.huobi");
            //     } else if (web == "bian") {
            //         return $t("tba.bian");
            //     } else if (web == "OKEx") {
            //         return $t("tba.OKEx");
            //     }
            // },
			name(v) {
				return v.split('/')[0]
			},
			name1(v) {
				return v.split('/')[1]
			},
			danwei(v) {
				if(v) {
					let arr = v.currency.split('_')
					let arr1 = v.currency_pair.split('/')
					if(arr[1] === 'usd') {
						return arr1[0]
					}else {
						return arr1[1]
					}
				}
			}
        },
        created() {
            // this.getBillList();
            // this.billPage = 1;
        },
        methods: {
			exchangeNameFilter(e) {
			    if (e == "huobi") {
			        return 'HUOBI';
			    } else if (e == "bian") {
			        return 'BIAN';
			    } else if (e == "okex") {
			        return 'OKEX';
			    }
			},
            onClickLeft() {
            	this.$router.back(-1);
            },
            to_bill() {
                this.$router.push("/bill");
            },
            profit_bill() {
                this.$router.push("/bill_sum");
            },

            getBillList() {
                this.$post2("Robot/api/index/getBillList", {
                    page: this.billPage++,
                }).then((res) => {
                    console.log(res);
                    this.billData = res.list;
                    this.total_profit = res.total_profit;
                    this.today_profit = res.today_profit;
                });
            },

            onLoadcashFlow() {
                this.getBillList();
                // 异步充值记录
                setTimeout(() => {
                    for (let i = 0; i < this.billData.length; i++) {
                        this.billList.push(this.billData[i]);
                    }
                    // console.log(this.shouyiList);
                    // 加载状态结束
                    this.billLoading = false;
                    // 数据全部加载完成

                    console.log(this.billData);
                    if (this.billData.length < 1) {
                        this.billFinished = true;
                    }
                }, 1200);
            },
			linkToBillback() {
			  this.$router.push("/bill_back");
			},
        },
    };
</script>
<style lang="less" scoped>
	body {
		// background-color: #F5F5F5;
	}
    .billBox {
		font-family: PingFangSC-Regular;
        .billNumBox{
            border-bottom: 0.2rem solid RGBA(246, 248, 249, 1);
            // padding: 0.2rem 0 0.5rem;
        }
        .billNum {
            text-align: center;
            padding: 0.4rem 0rem 0.6rem 0rem;


            p {
                font-size: 0.27rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #fff;
            }

            span {
                display: block;
                margin-top: 0.2rem;
                font-size: 0.36rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #fff;
            }
        }
		.cumulative{
			background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
			background-size: 100% 100%;
		}
		.today{
			background: url(../../assets/imgs/bill/jryl.png) no-repeat;
			background-size: 100% 100%;
		}
        .billList {
            margin: 0 0.2rem;
			.top {
				// border-bottom: 0.01rem solid #D3D9DE;
				padding: 0.2rem 0;
				display: flex;
				align-items: center;
				.kuai {
					margin-right: 0.22rem;
					width: 5px;
					height: 0.26rem;
					background: linear-gradient(131deg, #527EFF 0%, #214BF9 100%);
				}
				.caption {
					font-size: 0.32rem;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
					// margin-bottom: .25rem;
				}
			}
            .billItem {
				background: #FFFFFF;
				border-radius: 8px;
				margin-bottom: .2rem;
                // padding-bottom: 0.26rem;
                // border-bottom: 1px solid #f5f5f5;

                .billTitle {
                    // margin-top: 0.29rem;
                    font-weight: 400;
                    color: #02172E;
					line-height: .4rem;
					padding: .25rem .25rem .15rem;
					border-bottom: 1px solid #EDEDED;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.t_left {
						display: flex;
						align-items: center;
					}

                    i {
                        margin-right: 0.15rem;
                        font-size: 0.29rem;
                        vertical-align: 0.01rem;
                    }

                    span {
                        float: right;
                        font-size: 0.24rem;
                        font-family: Bahnschrift;
                        font-weight: 300;
                        color: #B5B4BA;
                        // margin-top: 0.1rem;
                    }
					
					.name {
						color: #333333;
						font-size: 0.28rem;
					}
					.name1 {
						color: #666666;
						font-size: 0.24rem;
					}
                }

                .billCent {
                    // margin-top: 0.3rem;
                    position: relative;
                    text-align: center;
					padding: 0.2rem 0 0.3rem 0;

                    p {
                        font-size: 0.24rem;
                        color: #666666;
                    }

                    span {
                        display: block;
                        font-size: 0.28rem;
                        // font-family: Bahnschrift;
                        // font-weight: 400;
                        color: #333333;
                        height: 0.3rem;
                    }

                    .billGreen {
                        font-size: 0.28rem;
                        // font-family: Bahnschrift;
                        // font-weight: 600;
                        color: #32BF88;
                    }

                    i {
                        width: 1.5px;
                        height: 0.6rem;
                        background: #F5F5F5;
                        position: absolute;
                        right: 0;
                        top: 0.4rem;
                    }
					&:last-child {
						padding-right: 0.22rem;
					}
                }
				.billCent1{
				    // margin-top: 0.3rem;
				    position: relative;
				    text-align: left;
					padding: 0.2rem 0 0.3rem 0;
					padding-left: 0.22rem;
				
				    p {
				        font-size: 0.24rem;
				        color: #666666;
				    }
				
				    span {
				        display: block;
				        font-size: 0.28rem;
				        // font-family: Bahnschrift;
				        // font-weight: 400;
				        color: #333333;
				        height: 0.3rem;
				    }
				
				    .billGreen {
				        font-size: 0.28rem;
				        // font-family: Bahnschrift;
				        // font-weight: 600;
				        color: #23A453;
				    }
				
				    i {
				        width: 1.5px;
				        height: 0.6rem;
				        background: #F5F5F5;
				        position: absolute;
				        right: 0;
				        top: 0.4rem;
				    }
				}
            }

            .billItem:last-child {
                border-bottom: none;
            }
        }
		
		
    }

// ----------20200410[start]---------
.communityInfo {
	// padding: .6rem .2rem 0;
	// height: 1.72rem;
	background: #FFFFFF;
	// box-shadow: 0 2px 18px 0px rgba(202, 202, 202, 0.3);
	// margin: 0 .3rem .5rem;
	position: relative;
	z-index: 2;
	.list {
		padding: 0.4rem 0;
		margin: ;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.item {
			width: 50%;
			color: #2E62FF;
			font-size: .24rem;
			// width: 3rem;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			.yen {
				color: #2E62FF;
				font-size: 0.36rem;
				font-weight: bold;
				// font-family: Bahnschrift;
				margin-bottom: 0.08rem;
			}
			.i_right {
				margin-left: 0.32rem;
				img {
					width: 0.24rem;
					height: 0.24rem;
				}
			}
		}
		.split {
			width: 1px;
			height: .6rem;
			background: #E7E7E7;
		}
	}
}


.layoutBox {
	padding-top: 1.6rem;
	margin-top: -1.6rem;
	min-height: calc(100vh - 1.8rem);
	position: relative;
	z-index: 1;
}
// ----------20200410[end]---------
</style>
